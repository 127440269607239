import background_4 from "../images/backgrounds/background_4.png";
import tokenomics_illustration from "../images/illustrations/tokenomics.png";
import { scroll_to_pannel, nudge } from "../services/navigation";
import Clickable_Div from "./clickable_div";

function Tokenomics() {

    return (
        // <div className="full_background outer_flex">
            // <img className="full_background_image" src={background_4}/>
            // <div className="container_outer">
                <div className="central_container_box forced_center">
                    <div className="cc_top_title white_background"><h2 className="handwritten">TOKENOMICS</h2></div>
                    <div className="standard_box_inner">
                        <div className="standard_box_text">

                            <img className="standard_illustration" src={tokenomics_illustration}/>
                        </div>

                        <Clickable_Div className="colored_button green_background green_button_hover" on_click={()=>{scroll_to_pannel(0); nudge(0);}}>Buy Now</Clickable_Div>


                        <div className="standard_box_text">
                            "Many Great Ideas were first scribbled down on a napkin. This one was drawn on a piece of toilet paper."<br/><br/>
                            — Unknown Author
                        </div>
                    </div>
                </div>
            // </div>
        // </div>
    )

}

export default Tokenomics;