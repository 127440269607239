export function is_valid_email(email) {
    const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexPattern.test(email);
}

export function is_valid_wallet_address(address) {
    const regexPattern = /^0x[a-fA-F0-9]{40}$/;
    return regexPattern.test(address);
}

export function is_non_zero_number(value) {
    return typeof value === 'number' && value !== 0;
}
  
export function short_format_number(number) {

    if (!number) { return 0; }
    if (number == "") { return 0; }
    if (number == "NaN") { return 0; }

    number = parseFloat(number);

    if (number < 1) {
        return number.toFixed(2);
    }

    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixIndex = Math.floor(Math.log10(number) / 3);

    if (suffixIndex == 0) {
        return number.toFixed(2);
    }

    const shortNumber = number / Math.pow(10, suffixIndex * 3);
    const formattedNumber = shortNumber.toFixed(1);

    return `${formattedNumber}${suffixes[suffixIndex]}`;
}

// export function add_thousand_separators(n) {
//     return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }  

export function add_thousand_separators(n) {
    let parts = n.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export function shorten_crypto_string(input_string, start_length=6, end_length=4) {
    if (!input_string || typeof input_string !== 'string') {
      throw new Error('Input must be a valid string');
    }
  
    if (input_string.length <= start_length + end_length) {
      return input_string;
    }
  
    const start = input_string.slice(0, start_length);
    const end = input_string.slice(-end_length);
    return `${start}...${end}`;
}

export function format_timestamp(timestamp) {
    const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds
    const hours = date.getHours();
    const minutes = date.getMinutes();
  
    // Format the time with leading zeros if necessary
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    return formattedTime;
}

export function format_dmy(timestamp) {
    const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds
    const day = date.getDate();
    const month = date.getMonth() + 1; // +1 because months are 0-indexed
    const year = date.getFullYear() % 100; // Get the last two digits of the year

    // Format the date with leading zeros if necessary
    const formattedDate = `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${String(year).padStart(2, '0')}`;
    return formattedDate;
}



export function append_utm_without_reloading(utm_params) {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    utm_params.split('&').forEach(param => {
        const [key, value] = param.split('=');
        searchParams.set(key, value);
    });

    const newUrl = `${currentUrl.origin}${currentUrl.pathname}?${searchParams.toString()}${currentUrl.hash}`;

    window.history.pushState({ path: newUrl }, '', newUrl);
}

export function remove_param_without_reloading(param_to_remove) {
    const current_url = new URL(window.location.href);
    const search_params = new URLSearchParams(current_url.search);

    search_params.delete(param_to_remove);

    const new_url = `${current_url.origin}${current_url.pathname}?${search_params.toString()}${current_url.hash}`;

    window.history.pushState({ path: new_url }, '', new_url);
}

