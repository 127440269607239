

export function scroll_to_pannel(index) {
    let pannels = document.querySelectorAll(".central_container_box");
    let rect = pannels[index].getBoundingClientRect();

    window.scrollBy({
        top: index == 0 ? rect.top+255 : rect.top-250,
        left: 0,
        behavior: 'smooth'
      });

    // pannels[index].scrollIntoView({ behavior: "smooth", block: "start" });
}


function nudge_element(element) {
    if (element.classList.contains("nudge_element")) {
        element.classList.remove("nudge_element");
        void element.offsetWidth;
    }
    setTimeout(() => {
        element.classList.add("nudge_element");
    }, 10);
}

let observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            nudge_element(entry.target)
        }
    });
}, {
    threshold: 0.1
});


export function nudge(index) {

    let boxes = document.querySelectorAll(".central_container_box");
    let box = boxes[index];
    let rect = box.getBoundingClientRect();

    if (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {
        nudge_element(box);
    }
    else {
        observer.observe(box);
    }

}
