import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

// ---- PAGES ----
import Home_Page from "./pages/home_page";

// ---- STYLES ----
import "./styles/colors.css";
import "./styles/fonts.css";
import "./styles/common.css";
import "./styles/header.css";
import "./styles/footer.css";
import "./styles/containers.css";
import "./styles/top_banner.css";
import "./styles/content.css";
import "./styles/faq.css";

import "./styles/animations.css";

import "./styles/sales_widget.css";
import "./styles/live_sales.css";
import "./styles/key_features.css";
import "./styles/modal.css";



// ---- WAGMI ----
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { wagmi_config } from "./configs/wagmi_config";
// import { ConnectKitProvider } from "connectkit";

// ---- MIX PANNEL ----
import mixpanel from 'mixpanel-browser';
mixpanel.init('3ab93d5a422a1ac056e4041341218ef4', {debug: true, track_pageview: true, persistence: 'localStorage'});

let user_agent = navigator.userAgent || navigator.vendor;

if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(user_agent.toLowerCase())) {
  import('./styles/hovers_mobile.css');
} else {
  import("./styles/hovers.css");
}


const projectId = 'b4fcdaeb8de0f73b1b573d9e1c74be79'

const queryClient = new QueryClient()
createWeb3Modal({
  wagmiConfig: wagmi_config,
  projectId,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <WagmiProvider config={wagmi_config}>
      <QueryClientProvider client={queryClient}> 
        {/* <ConnectKitProvider> */}
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home_Page />}/>
            </Routes>
          </BrowserRouter>
        {/* </ConnectKitProvider> */}
      </QueryClientProvider> 
    </WagmiProvider>
  </>
);

reportWebVitals();
