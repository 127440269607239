import { useState } from "react";
import { scroll_to_pannel, nudge } from "../services/navigation";


import butcoin from "../images/icons/butcoin.png";
import down_icon from "../images/icons/down.png";

import { useModal, Modal_Provider } from "../modals/modal_management";

import { get_default_language } from "../data/languages";

import { initialize_translator, set_translation_to_default } from "../services/language";

import Clickable_Div from "./clickable_div";

import background_1 from "../images/backgrounds/background_1.png";

function Header_Content() {

    const { open_modal, close_modal } = useModal();

    let [selected_language, set_selected_language] = useState(get_default_language())

    function select_language() {
        open_modal("language_selector", {set:set_selected_language})
    }

    window.addEventListener('scroll', function() {
        var headers = document.querySelectorAll('.header_outer_container');
        for (let i = 0; i < headers.length; i++) {
            let header = headers[i]
        
            if (window.scrollY > 0) {
            header.classList.add('header_outer_container_scrolled');
            } else {
            header.classList.remove('header_outer_container_scrolled');
            }
        }

        if (window.scrollY > 0) {
            headers[0].style.display = "block";
        }
        else {
            headers[0].style.display = "none";
        }
    });

    window.addEventListener('load', () => {
        // console.log("HEREE");
        (async function() {
            // await initialize_translator();
            // let default_language = await set_translation_to_default();
            // set_selected_language(default_language);
        })();
    })
      

    return (
        <>
            <div className="header_outer_container" style={{display: "none", backgroundColor: "black"}}>
                <img src={background_1} className="header_background"/>
            </div>
            <div className="header_outer_container">
                {/* <img src={background_1} className="header_background"/> */}
                <div className="header_container">
                    <img className="header_logo" src={butcoin}/>
                    <Clickable_Div className="header_language_selector standard_hover notranslate" on_click={select_language}>
                        <img className="header_flag_img" src={selected_language.icon}/>
                        <img className="header_lanaguage_down" src={down_icon}/>
                        <div className="header_language_lbl">{selected_language.iso_code.toUpperCase()}</div>
                    </Clickable_Div>
                    {/* <img className="header_flag_img" src={english_flag} onClick={select_language}/> */}
                    <Clickable_Div className="header_buy_button standard_hover" on_click={()=>{scroll_to_pannel(0); nudge(0);}}>Buy Now</Clickable_Div>
                    {/* <div className="header_mobile_menu"></div> */}
                    <div className="header_links_container">
                        {/* <Clickable_Div on_click={()=>scroll_to_pannel(2)} className="header_link clickable_text"><u>About</u></Clickable_Div> */}
                        <Clickable_Div on_click={()=>scroll_to_pannel(2)} className="header_link clickable_text"><u >Referral</u></Clickable_Div>
                        <Clickable_Div on_click={()=>scroll_to_pannel(4)} className="header_link clickable_text"><u>Tokenomics</u></Clickable_Div>
                        <Clickable_Div on_click={()=>scroll_to_pannel(6)} className="header_link clickable_text"><u>Support</u></Clickable_Div>
                        <Clickable_Div on_click={()=>scroll_to_pannel(8)} className="header_link clickable_text"><u>Socials</u></Clickable_Div>
                        {/* <u onClick={()=>scroll_to_pannel(9)} className="header_link clickable_text">Socials</u> */}
                    </div>
                </div>
            </div>
        </>
    )

}

function Header() {
    return (
        <Modal_Provider>
            <Header_Content/>
        </Modal_Provider>
    )
}

export default Header;