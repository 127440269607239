import matic_icon from "../images/crypto_icons/matic.png";
import eth_icon from "../images/crypto_icons/eth.png";
import bnb_icon from "../images/crypto_icons/bnb.png";
import btc_icon from "../images/crypto_icons/btc.png";
import usdc_icon from "../images/crypto_icons/usdc.png";
import usdt_icon from "../images/crypto_icons/usdt.png";
import xrp_icon from "../images/crypto_icons/xrp.png";
import sol_icon from "../images/crypto_icons/sol.png";
import ada_icon from "../images/crypto_icons/ada.png";
import avax_icon from "../images/crypto_icons/avax.png";
import dai_icon from "../images/crypto_icons/dai.png";
import doge_icon from "../images/crypto_icons/doge.png";
import wbtc_icon from "../images/crypto_icons/wbtc.png";

export let currencies = [
    {
        name: "Polygon",
        human_readable: "MATIC",
        symbol: "MATICPOLYGON",
        icon: matic_icon,
        fee: "No Fee",
        source: "Direct To Contract",
        default_value: 200,
        blockchain_info: "Polygon (MATIC)",
        significant_decimals: 2,
        blockchain_icon: matic_icon,
        index: 0
    },
    {
        name: "Ethereum",
        human_readable: "ETH",
        symbol: "ETH",
        icon: eth_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 0.1,
        blockchain_info: "Ethereum (ERC20)",
        significant_decimals: 4,
        blockchain_icon: eth_icon,
        index: 1
    },
    {
        name: "Solana",
        human_readable: "SOL",
        symbol: "SOL",
        icon: sol_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 1.0,
        blockchain_info: "Solana (SPL)",
        significant_decimals: 4,
        blockchain_icon: sol_icon,
        index: 2
    },
    {
        name: "Binance Coin",
        human_readable: "BNB",
        symbol: "BNBBSC",
        icon: bnb_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 0.30,
        blockchain_info: "Binance Smart Chain (BEP20)",
        significant_decimals: 4,
        blockchain_icon: bnb_icon,
        index: 3
    },
    {
        name: "Bitcoin",
        human_readable: "BTC",
        symbol: "BTC",
        icon: btc_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 0.003,
        blockchain_info: "Bitcoin",
        significant_decimals: 6,
        blockchain_icon: btc_icon,
        index: 4
    },
    {
        name: "USDT (Ethereum)",
        human_readable: "USDT",
        symbol: "USDT20",
        icon: usdt_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 200,
        blockchain_info: "Ethereum (ERC20)",
        significant_decimals: 2,
        blockchain_icon: eth_icon,
        index: 5
    },
    {
        name: "USDT (Solana)",
        human_readable: "USDT",
        symbol: "USDTSOL",
        icon: usdt_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 200,
        blockchain_info: "Solana (SPL)",
        significant_decimals: 2,
        blockchain_icon: sol_icon,
        index: 6
    },
    // {
    //     name: "USDT (Tron)",
    //     human_readable: "USDT",
    //     symbol: "USDTRX",
    //     icon: usdt_icon,
    //     fee: "0.25% Fee",
    //     source: "Via Changelly",
    //     default_value: 100,
    //     blockchain_info: "Tron (TRC20)",
    //     significant_decimals: 2,
    //     blockchain_icon: 
    // },
    {
        name: "USDT (BSC)",
        human_readable: "USDT",
        symbol: "USDTBSC",
        icon: usdt_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 200,
        blockchain_info: "Binance Smart Chain (BEP20)",
        significant_decimals: 2,
        blockchain_icon: bnb_icon,
        index: 7
    },
    {
        name: "USDT (Avalanche)",
        human_readable: "USDT",
        symbol: "USDTAVAC",
        icon: usdt_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 200,
        blockchain_info: "Avalanche (AVAX C-Chain)",
        significant_decimals: 2,
        blockchain_icon: avax_icon,
        index: 8
    },
    {
        name: "USDC (Ethereum)",
        human_readable: "USDC",
        symbol: "USDC",
        icon: usdc_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 200,
        blockchain_info: "Ethereum (ERC20)",
        significant_decimals: 2,
        blockchain_icon: eth_icon,
        index: 9
    },
    {
        name: "USDC (BSC)",
        human_readable: "USDC",
        symbol: "USDCBSC",
        icon: usdc_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 200,
        blockchain_info: "Binance Smart Chain (BEP20)",
        significant_decimals: 2,
        blockchain_icon: bnb_icon,
        index: 10
    },
    // {
    //     name: "USDC (Tron)",
    //     human_readable: "USDC",
    //     symbol: "USDCTRX",
    //     icon: usdc_icon,
    //     fee: "0.25% Fee",
    //     source: "Via Changelly",
    //     default_value: 100,
    //     blockchain_info: "Tron (TRC20)",
    //     significant_decimals: 2,
    //     blockchain_icon: sol_icon
    // },
    // {
    //     name: "USDC (Arbitrum)",
    //     human_readable: "USDC",
    //     symbol: "USDCARB",
    //     icon: usdc_icon,
    //     fee: "0.25% Fee",
    //     source: "Via Changelly",
    //     default_value: 100,
    //     blockchain_info: "Arbitrum",
    //     significant_decimals: 2,
    //     blockchain_icon: sol_icon
    // },
    {
        name: "USDC (Avalanche)",
        human_readable: "USDC",
        symbol: "USDCAVAC",
        icon: usdc_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 200,
        blockchain_info: "Avalanche (AVAX C-Chain)",
        significant_decimals: 2,
        blockchain_icon: avax_icon,
        index: 11
    },
    {
        name: "USDC (Solana)",
        human_readable: "USDC",
        symbol: "USDCSOL",
        icon: usdc_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 200,
        blockchain_info: "Solana (SPL)",
        significant_decimals: 2,
        blockchain_icon: sol_icon,
        index: 12
    },
    {
        name: "USDC (Polygon)",
        human_readable: "USDC",
        symbol: "USDCMATIC",
        icon: usdc_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 200,
        blockchain_info: "Polygon (MATIC)",
        significant_decimals: 2,
        blockchain_icon: matic_icon,
        index: 13
    },
    {
        name: "Cardano",
        human_readable: "ADA",
        symbol: "ADA",
        icon: ada_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 200,
        blockchain_info: "Cardano",
        significant_decimals: 2,
        blockchain_icon: ada_icon,
        index: 14
    },
    {
        name: "Avalanche",
        human_readable: "AVAX",
        symbol: "AVAX",
        icon: avax_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 2,
        blockchain_info: "Avalanche (AVAX C-Chain)",
        significant_decimals: 4,
        blockchain_icon: avax_icon,
        index: 15
    },
    {
        name: "Dogecoin",
        human_readable: "DOGE",
        symbol: "DOGE",
        icon: doge_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 500,
        blockchain_info: "Dogecoin",
        significant_decimals: 2,
        blockchain_icon: doge_icon,
        index: 16
    },
    // {
    //     name: "Ripple",
    //     human_readable: "XRP",
    //     symbol: "XRP",
    //     icon: xrp_icon,
    //     fee: "0.25% Fee",
    //     source: "Via Changelly",
    //     default_value: 200,
    //     blockchain_info: "XRP Ledger",
    //     significant_decimals: 2,
    //     blockchain_icon: xrp_icon,
    //     index: 17
    // },
    {
        name: "Dai",
        human_readable: "DAI",
        symbol: "DAI",
        icon: dai_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 200,
        blockchain_info: "Ethereum (ERC20)",
        significant_decimals: 2,
        blockchain_icon: eth_icon,
        index: 18
    },
    {
        name: "Wrapped Bitcoin",
        human_readable: "WBTC",
        symbol: "WBTC",
        icon: wbtc_icon,
        fee: "0.25% Fee",
        source: "Via Changelly",
        default_value: 0.0015,
        blockchain_info: "Ethereum (ERC20)",
        significant_decimals: 6,
        blockchain_icon: eth_icon,
        index: 19
    },
];
