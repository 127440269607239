import axios from 'axios';
import config from "../configs/config.json";

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const custom_axios = axios.create({
    baseURL: config.api_endpoint,
});

// custom_axios.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     (error) => {
//         if (error.response && error.response.status === 429) {
//             alert('You are making requests too quickly. Please slow down.');
//         }
//         return Promise.reject(error);
//     }
// );

const retry_request = async (error, retry_count = 3) => {
    if (retry_count <= 0) {
      throw new Error('Max retries reached');
    }
  
    await sleep(1000);
  
    try {
      const config = {
        ...error.config,
        retry: true,
      };
      return await custom_axios(config);
    } catch (err) {
      if (err.response && err.response.status !== 429) {
        return retry_request(err, retry_count - 1);
      }
      throw err;
    }
  };
  
  custom_axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
        if (error.response && error.response.status === 429) {
            alert('You are making requests too quickly. Please slow down.');
        }
        if (error.response && error.response.status !== 429 && !error.config.retry) {
            return retry_request(error);
        }
        return Promise.reject(error);
    }
);

export async function get_crypto_exchange_amount(currency_in, amount_in) {
    let response = await custom_axios.post(`/minting/get_crypto_exchange_amount`, { currency_in, amount_in });
    return response.data;
}

export async function create_crypto_transaction(recipient, referral, currency_in, amount_in) {
    let response = await custom_axios.post('/minting/create_crypto_transaction', { recipient, referral, currency_in, amount_in });
    return response.data;
}

export async function get_crypto_transaction_status(id) {
    let response = await custom_axios.post('/minting/get_crypto_transaction_status', { id });
    return response.data;
}

export async function get_crypto_transaction(id) {
    let response = await custom_axios.post('/minting/get_crypto_transaction', { id });
    return response.data;
}

export async function update_email(id, email, allow_marketing) {
    let response = await custom_axios.post('/minting/update_email', { id, email, allow_marketing });
    return response.data;
}

export async function fetch_transactions_by(criteria) {
    let response = await custom_axios.post('/minting/fetch_transactions_by', criteria);
    return response.data;
}

export async function get_fiat_providers(currency_in, amount_in, country_code) {
    let response = await custom_axios.post(`/minting/get_fiat_providers`, { currency_in, amount_in, country_code });
    return response.data;
}

export async function get_fiat_exchange_amount(currency_in, amount_in, country_code, provider) {
    let response = await custom_axios.post(`/minting/get_fiat_exchange_amount`, { currency_in, amount_in, country_code, provider });
    return response.data;
}

export async function create_fiat_transaction(recipient, referral, currency_in, amount_in, country_code, provider) {
    let response = await custom_axios.post(`/minting/create_fiat_transaction`, { recipient, referral, currency_in, amount_in, country_code, provider });
    return response.data;
}

export async function get_fiat_transaction(id) {
    let response = await custom_axios.post('/minting/get_fiat_transaction', { id });
    return response.data;
}

export async function get_fiat_transaction_status(id) {
    let response = await custom_axios.post('/minting/get_fiat_transaction_status', { id });
    return response.data;
}

export async function seppuku(samurai_address, v, r, s) {
    let response = await custom_axios.post('/minting/seppuku', { samurai_address, v, r, s });
    return response.data;
}

export async function register_email(email) {
    let response = await custom_axios.post('/analytics/register_email', { email });
    return response.data;
}

export async function create_manual_transaction(recipient, referral, amount_in) {
    let response = await custom_axios.post('/minting/create_manual_transaction', { recipient, referral, amount_in });
    return response.data;
}

export async function get_manual_transaction(id) {
    let response = await custom_axios.post('/minting/get_manual_transaction', { id });
    return response.data;
}

export async function get_manual_transaction_status(id) {
    let response = await custom_axios.post('/minting/get_manual_transaction_status', { id });
    return response.data;
}

export async function register_referral(address) {
    let response = await custom_axios.post('/analytics/register_referral', { address });
    return response.data;
}

export async function get_active_address_by_id(id) {
    let response = await custom_axios.post('/analytics/get_active_address_by_id', { id });
    return response.data;
}