import background_2 from "../images/backgrounds/background_2.png";
import hero from "../images/illustrations/hero.png";
import { scroll_to_pannel, nudge } from "../services/navigation";
import Clickable_Div from "./clickable_div";


function About() {

    return (
        // <div className="full_background outer_flex">
            // <img className="full_background_image" src={background_2}/>
            // <div className="container_outer">
                <div className="central_container_box forced_center switch_hook">
                    <div className="cc_top_title pink_background"><h2>ABOUT BUTCOIN</h2></div>
                    <div className="standard_box_inner">
                        <div className="standard_box_text">

                            <img className="standard_illustration" src={hero}/>

                            Tired of the endless derivative ShibaWifScarf 420 Inu coins? The derivatives have had their day.<br/><br/>

                            
                            Welcome to the Ultimate Bitcoin Parody Meme Coin. Finally a Meme Coin focused
                        </div>

                        <Clickable_Div className="colored_button pink_background pink_button_hover" on_click={()=>{scroll_to_pannel(0); nudge(0);}}>Buy Now</Clickable_Div>

                        <div className="standard_box_text">
                            "This is so crazy it might actually work"<br/>
                            -- you (probably)
                        </div>
                    </div>
                    
                </div>
            // </div>

        // </div>
    )

}

export default About;