import { useState, useEffect, useRef } from "react";
import Clickable_Div from "../components/clickable_div";
import external_links from "../configs/external_links.json";


function Unknown_Geo({ close_modal, parameters }) {

    return (
        <>
            <div className="modal_title"><h3>Unknown Location</h3></div>
            <h3>We are unable to detect your location</h3>
            <br/>
            <p>ButtChain is not available in a few countries. By continuing you hereby certify that you are not a citizen or resident of the United States of America, Canada, the UK, any banned country, or any other country where the purchase of ButtChain may be prohibited by law. A complete list of banned countries is <Clickable_Div className="inline_content clickable_text" on_click={()=>window.open(external_links.blocked_countries)}><u>available here</u></Clickable_Div>. Our list of blocked countries and terms of service may be subject to updates, so please check the latest version.</p>
            <Clickable_Div className="other_button other_button_first green_background green_button_hover" style={{width: "calc(100% - 6px)"}} on_click={()=>{
                close_modal();
            }}><b>Continue</b></Clickable_Div>
        </>
    )

}


export default Unknown_Geo;