
function Seperator({ index }) {
    return (
        <>
        {
            index == 0 ?
            <div className="small_seperator"></div> :
            <div className="med_seperator sep_hook"></div>
        }
        </>
        
    );
}

export default Seperator;