import { useEffect, useRef, useState } from "react";
import Header from "../components/header";
import Sales_Widget from "../components/sales_widget";
import Live_Sales from "../components/live_sales";
import About from "../components/about";
import Key_Features from "../components/key_features";
import Proof_Of_Butt from "../components/proof_of_butt";
import Tokenomics from "../components/tokenomics";
import Smart_Contracts from "../components/smart_contracts";
import How_To_Buy from "../components/how_to_buy";
import FAQ from "../components/faq";
import Socials from "../components/socials";
import Footer from "../components/footer";
import Seperator from "../components/seperator";
import Extra_Section from "../components/extra_section";
import Presale_Ended from "../components/presale_ended";


// import Swap_Widget from "../components/swap_widget";
import config from "../configs/config.json";

import background_1 from "../images/backgrounds/background_1.png";
import background_2 from "../images/backgrounds/background_2.png";
import background_3 from "../images/backgrounds/background_3.png";
import background_4 from "../images/backgrounds/background_4.png";
import background_5 from "../images/backgrounds/background_5.png";

let background_imgs = [
    background_1, 
    background_1,
    background_2,
    background_2,
    background_3,
    background_3,
    background_4,
    background_4,
    background_5,
    background_5,
];

if (config.status != "presale") {
    background_imgs.splice(1, 1);
}


function Home_Page() {

    let background_ref = useRef();
    let [img_index, set_img_index] = useState(0);


    window.onload = () => {

        let cur_i = 0;
        let targets = document.querySelectorAll('.central_container_box'); // central_container_box

        console.log(targets);

        let can_change = true;


        function update_img(s_i, quick_load=false) {


            set_img_index(Math.ceil(cur_i/2));

            if (background_ref.current.true_src == background_imgs[cur_i]) {
                return;
            }

            setTimeout(()=>{

            if (s_i != cur_i) { return; }

                try {

                    if (quick_load) {
                        background_ref.current.src = background_imgs[cur_i];
                        background_ref.current.true_src = background_imgs[cur_i];
                        document.querySelector(".header_background").src = background_imgs[cur_i];
                    }
                    else {
                        background_ref.current.classList.add("fixed_background_img_change");
                        setTimeout(()=>{
                            background_ref.current.src = background_imgs[cur_i];
                            background_ref.current.true_src = background_imgs[cur_i];
                            document.querySelector(".header_background").src = background_imgs[cur_i];
                        },250);
                        setTimeout(()=>{
                            background_ref.current.classList.remove("fixed_background_img_change");
                        },500);
                    }
                } catch (error) {}

            },100)
        }

        let tolerance = 600;

        function on_scroll(quick_load=false) {
            let rect = targets[cur_i].getBoundingClientRect();

            // if (rect.bottom <= 150) {
            if (rect.bottom <= tolerance) {
                cur_i = cur_i < targets.length-1 ? cur_i+1 : targets.length-1;
                update_img(cur_i, quick_load);
            }

            if (cur_i > 0) {
                let prev_rect = targets[cur_i-1].getBoundingClientRect();
                // if (prev_rect.bottom > 150) {
                if (prev_rect.bottom > tolerance) {
                    cur_i--;
                    update_img(cur_i, quick_load);
                }
            }

            if (quick_load) {
                update_img(cur_i, quick_load);
            }

        }

        window.addEventListener('scroll', ()=>{
            for (let x = 0; x < targets.length; x++) {
                on_scroll(false)
            }
        });
        for (let x = 0; x < targets.length; x++) {
            on_scroll(true);
        }

        let small_seperators = document.querySelectorAll(".sep_hook");
        let hidable_0 = document.querySelector(".hidable_0");
        let hidable_1 = document.querySelector(".hidable_1");

        let switch_tolerance = 100;

        function check_and_hide() {

            for (let i = 0; i < small_seperators.length; i++) {
                let rect_1 = small_seperators[i].getBoundingClientRect();
                let rect_2 = hidable_0.getBoundingClientRect();
                
                if (rect_1.top - switch_tolerance <= rect_2.bottom && rect_1.top + switch_tolerance >= rect_2.top) {
                    hidable_0.style.opacity = 0;
                    break;
                }
                else {
                    hidable_0.style.opacity = 1;
                }
            }

            for (let i = 0; i < small_seperators.length; i++) {

                let rect_1 = small_seperators[i].getBoundingClientRect();
                let rect_3 = hidable_1.getBoundingClientRect();

                if (rect_1.top - switch_tolerance <= rect_3.bottom && rect_1.top + switch_tolerance >= rect_3.top) {
                    hidable_1.style.opacity = 0;
                    break;
                }
                else {
                    hidable_1.style.opacity = 1;
                }

            }
        }

        window.addEventListener('scroll', check_and_hide);
        check_and_hide();
        
    }
    


    return (
        <>
            
            <img ref={background_ref} className="fixed_background_img"/>

            <div className="header_spacer"></div>
            <div className="fixed_butcoin_text_logo">
                <Extra_Section index={img_index} opacity={0}/>
            </div>

            {/* <img className="fixed_butcoin_text_logo" src={butcoin_text}/> */}
            <Extra_Section index={0} opacity={0}/>
            <Sales_Widget/>
            <Seperator index={1}/>
            <Live_Sales/>

            <Seperator index={0}/>
            <Extra_Section index={1} opacity={0}/>
            <Seperator index={0}/>
            <Proof_Of_Butt/>
            <Seperator index={1}/>
            <Key_Features/>

            <Seperator index={0}/>
            <Extra_Section index={2} opacity={0}/>
            <Seperator index={0}/>
            <Seperator index={0}/>
            <Tokenomics/>
            <Seperator index={1}/>
            <Smart_Contracts/>


            <Seperator index={0}/>
            <Extra_Section index={3} opacity={0}/>
            <Seperator index={0}/>
            <How_To_Buy/>
            <Seperator index={1}/>
            <FAQ/>
            
            <Seperator index={0}/>
            <Extra_Section index={4} opacity={0}/>
            <Seperator index={0}/>
            <Socials/>
            <Seperator index={1}/>
            <Footer/>
            <Seperator index={1}/>




            {/* <img className="fixed_butcoin_text_logo" src={butcoin_text}/> */}

            {/* <Extra_Section index={0} opacity={0}/>
            { config.status == "presale" ?
                <>
                    <Sales_Widget/>
                    <Seperator index={1}/>
                    <Live_Sales/>
                </> :
                config.status == "soon" ?
                <Presale_Ended/> :
                <Swap_Widget/>
            }
            
            <Seperator index={0}/>
            <Extra_Section index={1} opacity={0}/>
            <Seperator index={0}/>
            <Proof_Of_Butt/>
            <Seperator index={1}/>
            <Key_Features/>

            <Seperator index={0}/>
            <Extra_Section index={2} opacity={0}/>
            <Seperator index={0}/>
            <Seperator index={0}/>
            <Tokenomics/>
            <Seperator index={1}/>
            <Smart_Contracts/>


            <Seperator index={0}/>
            <Extra_Section index={3} opacity={0}/>
            <Seperator index={0}/>
            <How_To_Buy/>
            <Seperator index={1}/>
            <FAQ/>
            
            <Seperator index={0}/>
            <Extra_Section index={4} opacity={0}/>
            <Seperator index={0}/>
            <Socials/>
            <Seperator index={1}/>
            <Footer/>
            <Seperator index={1}/> */}

            <Header/>
            
            
        </>
    )
}

export default Home_Page;