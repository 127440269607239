import { useModal, Modal_Provider } from "../modals/modal_management";
import Clickable_Div from "./clickable_div";
import external_links from "../configs/external_links.json";

import { disconnect } from '@wagmi/core';
import { useAccount } from 'wagmi';

import { wagmi_config } from "../configs/wagmi_config";

function Footer_Content() {

    const { isConnected }  = useAccount();

    const { open_modal, close_modal } = useModal();

    function order_status() {
        open_modal("sales_tracking");
    }

    function track_referrals() {
        open_modal("referrals");
    }

    async function disconnect_wallet() {
        await disconnect(wagmi_config);
    }

    return (
        // <div className="footer_container">
        //     <div className="footer_email_container">
        //         <img className="footer_email_icon"/>
        //         <u className="clickable_text">support@butcoin.xyz</u>
                
        //     </div>
        //     <div className="footer_items_row">
        //         <u className="footer_item clickable_text">Terms of Use</u>
        //         <u className="footer_item clickable_text">Privacy Policy</u>
        //         <u className="footer_item clickable_text">Diclaimer and Risks</u>
        //         <br/>
        //         <u className="footer_item clickable_text">Whitepaper</u>
        //         <u className="footer_item clickable_text">Help Guides</u>
        //         <u className="footer_item clickable_text">Audit Report</u>
        //         <br/>
        //         <Clickable_Div on_click={order_status} className="footer_item clickable_text">
        //         <u>Order Status</u>
        //         </Clickable_Div>
        //         <Clickable_Div on_click={track_referrals} className="footer_item clickable_text">
        //         <u>Referral Status</u>
        //         </Clickable_Div>
        //     </div>
        // </div>

        <div className="central_container_box forced_center">
            <div className="cc_top_title blue_background"><h2 className="handwritten">LINKS</h2></div>
            <div className="standard_box_inner">
                <div className="standard_box_text">
                    {/* <img className="standard_illustration" src={social}/> */}
                    {/* <br/><br/> */}
                    <table className="standard_table notranslate">
                       
                        <tr>
                            <td>
                                <Clickable_Div on_click={() => window.open(external_links.terms)}>
                                    <u className="clickable_text">Terms of Use</u>
                                </Clickable_Div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Clickable_Div on_click={() => window.open(external_links.privacy)}>
                                    <u className="clickable_text">Privacy Policy</u>
                                </Clickable_Div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Clickable_Div on_click={() => window.open(external_links.disclaimer)}>
                                    <u className="clickable_text">Disclaimer and Risks</u>
                                </Clickable_Div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Clickable_Div on_click={() => window.open(external_links.whitepaper)}>
                                    <u className="clickable_text">Whitepaper</u>
                                </Clickable_Div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Clickable_Div on_click={() => window.open(external_links.guides)}>
                                    <u className="clickable_text">Help Guides</u>
                                </Clickable_Div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Clickable_Div on_click={() => window.open(external_links.audit)}>
                                    <u className="clickable_text">Audit Report</u>
                                </Clickable_Div>
                            </td>
                        </tr>



                        <tr>
                            <td>
                                <Clickable_Div on_click={track_referrals}>
                                    <u className="clickable_text">Referral Tracking</u>
                                </Clickable_Div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Clickable_Div on_click={order_status}>
                                    <u className="clickable_text">Order Status</u>
                                </Clickable_Div>
                            </td>
                        </tr>
                        { isConnected &&
                            <tr>
                                <td>
                                    <Clickable_Div on_click={disconnect_wallet}>
                                        <u className="clickable_text">Disconnect Wallet</u>
                                    </Clickable_Div>
                                </td>
                            </tr>
                        }
                        
                    </table>
                    <br/><br/>
                    <p style={{fontSize: "12px"}}>
                    <b>DISCLAIMER</b>
                    <br/><br/>
                    By visiting this website, using our services, or buying ButtChain, you agree to our <Clickable_Div className="inline_content clickable_text" on_click={()=>window.open(external_links.terms)}><u>Terms of Service</u></Clickable_Div> and <Clickable_Div className="inline_content clickable_text" on_click={()=>window.open(external_links.privacy)}><u>Privacy Policy</u></Clickable_Div>. You also warrant that you have read and fully understood the <Clickable_Div className="inline_content clickable_text" on_click={()=>window.open(external_links.disclaimer)}><u>Disclaimer</u></Clickable_Div> about the risks of purchasing cryptocurrencies like ButtChain. By completing a purchase, you also declare that you are not a citizen or resident of any banned country, or any other country where the purchase of ButtChain may be prohibited by law. A complete list of banned countries is <Clickable_Div className="inline_content clickable_text" on_click={()=>window.open(external_links.blocked_countries)}><u>available here</u></Clickable_Div>. Our list of blocked countries and terms of service may be subject to updates, so please check the latest version.
                    <br/><br/>
                    ButtChain is not an investment; it is created as a funny parody of other meme coins and is not associated with Bitcoin. We do not guarantee or represent that ButtChain holds any intrinsic value, that It will increase in price after the presale, or that it will have any value after the presale. We make no representations or warranties regarding ButtChain and are not liable for any losses or errors that may occur during its use. All users should exercise caution and buy ButtChain entirely at their own risk.
                    <br/><br/>
                    By using the ButtChain platform and product, you accept it "as is", acknowledging that we may not update, enhance, or maintain it regularly. The services and interface may be unavailable or discontinued at any time. However, the underlying smart contracts remain directly accessible on the blockchain, independent of our provided service or interface.
                    </p>
                    <br/><br/>
                    <p style={{fontSize: "12px"}}>© ButtChain Copyright 2024<br/>All Rights Reserved</p>

                </div>
            </div>
        </div>
    )
}

function Footer() {
    return (
        <Modal_Provider>
            <Footer_Content/>
        </Modal_Provider>
    )
}

export default Footer;