import { useState, useEffect, useRef } from "react";
import { get_mints_by_recipient } from "../services/web3_getters";
import { useAccount, useConnect, useNetwork } from 'wagmi';
// import { useWeb3Modal } from '@web3modal/react';
import Clickable_Div from "../components/clickable_div";
import { currencies } from "../data/cryptos";
import { ethers } from "ethers";
// import usa_icon from "../images/icons/usa.png";
import { short_format_number, format_timestamp, is_valid_wallet_address, format_dmy } from "../services/helper_functions";
import butcoin_icon from "../images/icons/butcoin.png";
import cross_icon from "../images/icons/cross.png";
import { fetch_transactions_by } from "../services/api";
import credit_card from "../images/illustrations/credit_card.png";

let usd = {
    name: "Credit Card",
    symbol: "USD",
    icon: credit_card,
    fee: "",
    source: "",
    default_value: 100,
    blockchain_info: "",
    significant_decimals: 2
}

function Sales_Tracking({ close_modal, parameters }) {

    let address_input = useRef();
    let [transactions, set_transactions] = useState([]);
    let [pending, set_pending] = useState([]);
    let [failed, set_failed] = useState([]);

    async function search() {

        let address = address_input.current.value;

        if (!is_valid_wallet_address(address)) {
            alert("Please enter a valid wallet address")
        }
        else {

            let transactions = [];

            let txs = await get_mints_by_recipient(address);

            for (let i = 0; i < txs.length; i++) {
                let tx = txs[i];
                let metadata = JSON.parse(tx.metadata);

                let currency;

                if (metadata.currency == "POLYGONMATIC") {
                    metadata.currency = "MATICPOLYGON";
                }

                if (metadata.currency.toUpperCase() == "USD") {
                    currency = usd;
                }
                else {
                    currency = currencies.find(cur => cur.symbol.toUpperCase() === metadata.currency.toUpperCase());
                } 

                if (!currency) {
                    currency = currencies[0];
                }

                console.log(currency);

                let transaction = {
                    type: "success",
                    amount: ethers.formatUnits(tx.butcoin_amount),
                    currency,
                    timestamp: parseInt(tx.timestamp),  
                }

                console.log({transaction})
                transactions.push(transaction);
            }

            let orders = await fetch_transactions_by({recipient:address});
            console.log({orders});

            let pending = [];
            let failed = [];

            for (let i = 0; i < orders.length; i++) {
                let order = orders[i];
                if (!["finished","complete"].includes(order.real_status)) {

                    let currency;

                    if (order.currency == "POLYGONMATIC") {
                        order.currency = "MATICPOLYGON";
                    }

                    if (order.currency.toUpperCase() == "USD") {
                        currency = usd;
                    }
                    else {
                        currency = currencies.find(cur => cur.symbol.toUpperCase() === order.currency.toUpperCase());
                    } 

                    if (!currency) {
                        currency = currencies[0];
                    }

                    let transaction = {
                        type: order.real_status,
                        amount: order.expected_amount_in,
                        currency,
                        timestamp: parseInt(order.created_date),  
                        id: order.id
                    }

                    if (order.status != "complete") {
                        pending.push(transaction);
                    }
                    else {
                        failed.push(transaction);
                    }

                }
            }

            set_transactions(transactions);
            set_pending(pending);
            set_failed(failed);
        }

        
        

    }

    return (
        <>
            <Clickable_Div on_click={close_modal}>
                <img className="close_modal" src={cross_icon}/>
            </Clickable_Div>
            <div className="modal_title"><h3>Order Status</h3></div>
            <h3>Track Purchases</h3>
            <br/>
            <input className="mail_list_input" placeholder="Wallet address" ref={address_input}/>
            <Clickable_Div className="mail_list_subscribe_button purple_background purple_button_hover" on_click={search}>Search</Clickable_Div>
            <div className="currency_list_container">
                <h3>Pending Purchases</h3>
                <br/>
                { pending.map((item, index) => (
                    <div className="live_sales_item">
                        <img className="live_sales_icon" src={item.currency.icon}/>
                        <div className="live_sales_title notranslate"><b>{parseFloat(item.amount).toFixed(item.currency.significant_decimals)} {item.currency.symbol}</b></div>
                        <div className="live_sales_desc notranslate"><a className="orange_text">{item.type}</a> {item.id}</div>
                        <div className="live_sales_timestamp">{format_timestamp(item.timestamp)}</div>
                        <div className="live_sales_date">{format_dmy(item.timestamp)}</div>
                    </div>
                )) }
                <h3>Successful Purchases</h3>
                <br/>
                { transactions.map((item, index) => (
                    <div className="live_sales_item">
                        <img className="live_sales_icon" src={item.currency.icon}/>
                        <div className="live_sales_title notranslate"><b>{short_format_number(item.amount)} ButtChain</b></div>
                        <div className="live_sales_desc green_text notranslate">{item.type}</div>
                        <div className="live_sales_timestamp">{format_timestamp(item.timestamp)}</div>
                        <div className="live_sales_date">{format_dmy(item.timestamp)}</div>
                    </div>
                )) }
                <h3>Failed Purchases</h3>
                <br/>
                { failed.map((item, index) => (
                    <div className="live_sales_item">
                        <img className="live_sales_icon" src={item.currency.icon}/>
                        <div className="live_sales_title notranslate"><b>{parseFloat(item.amount).toFixed(item.currency.significant_decimals)} {item.currency.symbol}</b></div>
                        <div className="live_sales_desc notranslate"><a className="red_text">{item.type}</a> {item.id}</div>
                        <div className="live_sales_timestamp">{format_timestamp(item.timestamp)}</div>
                        <div className="live_sales_date">{format_dmy(item.timestamp)}</div>
                    </div>
                )) }
            </div>
        </>
    )

}


export default Sales_Tracking;