import { useState } from "react";
import background_2 from "../images/backgrounds/background_2.png";
import { currencies } from "../data/cryptos";
import { scroll_to_pannel, nudge } from "../services/navigation";
import { get_recent_mints } from "../services/web3_getters";
import { ethers } from "ethers";
import { short_format_number, format_timestamp, format_dmy } from "../services/helper_functions";
import Clickable_Div from "./clickable_div";
// import usa_icon from "../images/icons/usa.png";
import credit_card from "../images/illustrations/credit_card.png";

let usd = {
    name: "Credit Card",
    symbol: "USD",
    icon: credit_card,
    fee: "",
    source: "",
    default_value: 100,
    blockchain_info: "",
    significant_decimals: 2
}

function Live_Sales() {

    let [transactions, set_transactions] = useState([]);

    useState(()=> {
        (async function() {

            let txs = await get_recent_mints(10);
            let transactions = [];

            for (let i = 0; i < txs.length; i++) {

                try {
                    let tx = txs[i];

                    let metadata;

                    try {
                        metadata = JSON.parse(tx.metadata);
                    } catch (error) {
                        metadata = {type:"mint",currency:"MATICPOLYGON"};
                    }
                     

                    let currency;

                    // console.log(metadata)

                    if (metadata.currency == "POLYGONMATIC") {
                        metadata.currency = "MATICPOLYGON";
                    }

                    if (metadata.currency.toUpperCase() == "USD") {
                        currency = usd;
                    }
                    else {
                        currency = currencies.find(cur => cur.symbol.toUpperCase() === metadata.currency.toUpperCase());
                    }
                    
                    if (!currency) {
                        currency = currencies[0];
                    }

                    let transaction = {
                        type: metadata.type == "mint" ? "Presale Mint" : "Uniswap",
                        amount: ethers.formatUnits(tx.butcoin_amount),
                        currency,
                        timestamp: parseInt(tx.timestamp)
                    }
                    transactions.push(transaction);
                    console.log(transaction)
                } catch (error) {}
            }

            console.log(transactions)
            transactions.reverse();
            set_transactions(transactions);

        })();
    },[])

    return (
        // <div className="full_background outer_flex">
            // <img className="full_background_image" src={background_2}/>
            // <div className="container_outer">
                <div className="central_container_box forced_center">
                    <div className="cc_top_title green_background"><h2 className="handwritten">RECENT SALES</h2></div>
                    <div className="standard_box_inner">
                        <div className="live_sales_container">
                            { transactions.map((item, index) => (
                                <div className="live_sales_item">
                                    <img className="live_sales_icon" src={item.currency.icon}/>
                                    <div className="live_sales_title notranslate"><b>{short_format_number(item.amount)} ButtChain</b></div>
                                    <div className="live_sales_desc notranslate">{item.type}</div>
                                    <div className="live_sales_timestamp">{format_timestamp(item.timestamp)}</div>
                                    <div className="live_sales_date">{format_dmy(item.timestamp)}</div>
                                </div>
                            )) }
                        </div>

                        <Clickable_Div className="colored_button green_background green_button_hover" on_click={()=>{scroll_to_pannel(0); nudge(0);}}>Buy Now</Clickable_Div>

                        {/* <div className="standard_button_container">
                            <div className="standard_button_gradient"></div>
                            <div className="standard_button_inner"><b>Buy Now</b></div>
                        </div> */}

                        <div className="standard_box_text">
                            "This is so crazy it might actually work"<br/><br/>
                            — you (probably)
                        </div>
                    </div>
                    
                </div>
            // </div>

        // </div>
    )

}

export default Live_Sales;