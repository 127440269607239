import Clickable_Div from "../components/clickable_div";
import external_links from "../configs/external_links.json";
import social from "../images/illustrations/social.png";

function Referral_Warning({ close_modal, parameters }) {

    return (
        <>
            <div className="modal_title"><h3>Referral Detected</h3></div>
            <h3>You have been referred by a friend</h3>
            <br/>
            <img className="standard_illustration" src={social}/>
            <br/><br/>
            <p>
                We've noticed that you've followed a unique referral link to ButtChain. By proceeding, you acknowledge that a portion of your purchase will be allocated to the individual who referred you. Please note that this will not impact the price of your purchase, and the amount of ButtChain you receive per dollar spent remains unchanged whether you use a referral link or not.
            </p>

            <Clickable_Div className="other_button other_button_first green_background green_button_hover" on_click={()=>{
                close_modal();
            }}><b>Continue with Referral</b></Clickable_Div>

            <Clickable_Div className="other_button light_hover" on_click={()=>{
                window.location = "/";
            }}><b>Opt Out of Referral</b></Clickable_Div>

            <br/><br className="hide_small"/>
            <p style={{fontSize: "12px"}}>All referres are required to comply with our <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.referral_guidelines)}><u className="clickable_text">Referral Guidelines</u></Clickable_Div>. If you feel that someone has violated our promotion guidelines and is recklessly promoting ButtChain please immediately contact support@buttchain.co.</p>
        </>
    );

}

export default Referral_Warning;