import { useState, useRef, useEffect } from "react";

// ---- Configs ----
import config from "../configs/config.json";
import external_links from "../configs/external_links.json";
import contract_addresses from "../configs/contract_addresses.json";
import butcoin_json from "../contract_abis/Butcoin.sol/Butcoin.json";
import { wagmi_config } from "../configs/wagmi_config";

// ---- Modals ----
import { useModal, Modal_Provider } from "../modals/modal_management";

// ---- Components ----
import Clickable_Div from "./clickable_div";

// ---- Services ----
import { is_valid_wallet_address, short_format_number, add_thousand_separators, remove_param_without_reloading } from "../services/helper_functions";
import { trigger_event } from "../services/analytics";
import { get_crypto_exchange_amount, get_fiat_exchange_amount, get_active_address_by_id, get_fiat_providers } from "../services/api";
import { get_country, validate_country } from "../services/location";
import { get_presale_supply, get_liquidity, get_exchange_rate, get_next_price_increase } from "../services/web3_getters";
import { scroll_to_pannel } from "../services/navigation";

// ---- Icons ----
import usa_icon from "../images/icons/usa.png";
import down_icon from "../images/icons/down.png";
import butcoin_icon from "../images/icons/butcoin.png";
import tick_icon from "../images/icons/tick.png";
import payment_methods from "../images/illustrations/payment_methods.png";
import more_icon from "../images/icons/more.png";
import moonpay_icon from "../images/icons/moonpay.png";
import banxa_icon from "../images/icons/banxa.png";
import wert_icon from "../images/icons/wert.png";
import transak_icon from "../images/icons/transak.png"

// ---- Data ----
import { currencies } from "../data/cryptos";

// ---- WEB3 ----
import { ethers } from "ethers";

// ---- WAGMI ----
import { useAccount, useConnect } from 'wagmi';
import { switchChain, writeContract, sendTransaction, disconnect, signMessage } from '@wagmi/core';

let null_address = "0x0000000000000000000000000000000000000000";

let fiat_icon_map = {
    "moonpay": moonpay_icon,
    "banxa": banxa_icon,
    "wert": wert_icon,
    "transak": transak_icon
}

let crypto_suggestions = [
    {
        amount: "50",
        currency: currencies[0]
    },
    // {
    //     amount: "200",
    //     currency: currencies[0]
    // },
    // {
    //     amount: "1,000",
    //     currency: currencies[0]
    // },
    {
        amount: "0.02",
        currency: currencies[1]
    },
    // {
    //     amount: "0.05",
    //     currency: currencies[1]
    // },
    // {
    //     amount: "0.5",
    //     currency: currencies[1]
    // },
    {
        amount: "0.5",
        currency: currencies[2]
    },
    // {
    //     amount: "2",
    //     currency: currencies[2]
    // },
    // {
    //     amount: "10",
    //     currency: currencies[2]
    // },
    {
        amount: "0.1",
        currency: currencies[3]
    },
    // {
    //     amount: "0.5",
    //     currency: currencies[3]
    // },
    // {
    //     amount: "2",
    //     currency: currencies[3]
    // },
    {
        amount: "50",
        currency: currencies[5]
    },
    // {
    //     amount: "200",
    //     currency: currencies[5]
    // },
    // {
    //     amount: "1,000",
    //     currency: currencies[5]
    // }
    {}
]

function Sales_Widget_Content() {

    // ---- Refs ----
    let crypto_amount_input_ref = useRef();
    let fiat_amount_input_ref = useRef();
    let butcoin_output_ref = useRef();
    let crypto_timeout_ref = useRef(null);
    let fiat_timeout_ref = useRef(null);
    let countdown_timer_ref = useRef(null);
    let inner_progress_ref = useRef(null);

    let days_ref = useRef(null);
    let hours_ref = useRef(null);
    let minutes_ref = useRef(null);
    let seconds_ref = useRef(null);

    // ---- Local Hooks ----
    let [tab, set_tab] = useState(0);
    let [agreed_tos, set_agreed_tos] = useState(true);
    let [crypto_focused, set_crypto_focused] = useState(false);
    let [fiat_focused, set_fiat_focused] = useState(false);
    let [min_max, set_min_max] = useState({min:5, max:100000});
    let [selected_crypto, set_selected_crypto] = useState(currencies[0]);
    let [referral_address, set_referral_address] = useState(null_address);
    let [country, set_country] = useState("FR");
    let [exchange_rate, set_exchange_rate] = useState(10);
    let [liquidity, set_liquidity] = useState(0);
    let [cur_supply, set_cur_supply] = useState(0);
    let [prices_dates, set_prices_dates] = useState([0,0]);
    let [amount_suggesions, set_amount_suggesions] = useState(crypto_suggestions);
    let [fiat_providers, set_fiat_providers] = useState([]);
    let [fiat_provider_index, set_fiat_provider_index] = useState(0);

    // ---- Currency Changes ----
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function set_fiat_price() {

        try {

            let amount = parseFloat(fiat_amount_input_ref.current.value);

            if (amount > 10000) {
                fiat_amount_input_ref.current.value = parseFloat(5000).toFixed(2);
            }
            if (amount < 20) {
                fiat_amount_input_ref.current.value = parseFloat(20).toFixed(2);
            }

            amount = parseFloat(fiat_amount_input_ref.current.value);

            if (!fiat_providers) {
                await sleep(1000);
            }

            let response = await get_fiat_exchange_amount("USD", amount, country, fiat_providers[fiat_provider_index]);

            if (!response) {
                await sleep(1000);
                response = await get_fiat_exchange_amount("USD", amount, country, fiat_providers[fiat_provider_index]);
            }

            if (!response) {
                return;
            }

            butcoin_output_ref.current.value = (exchange_rate * parseFloat(response.amountExpectedTo) - 0.25).toFixed(2);
        } catch (error) {}
    }

    function fiat_input_changed() {
        if (fiat_timeout_ref.current !== null) {
            clearTimeout(fiat_timeout_ref.current);
        }

        fiat_timeout_ref.current = setTimeout(async function() {
            trigger_event("change_credit_card_amount", 0);
            set_fiat_price();
        }, 1000);
    }

    async function set_crypto_price(override=false) {

        try {

            let amount = parseFloat(crypto_amount_input_ref.current.value);

            if (amount > min_max.max) {
                crypto_amount_input_ref.current.value = parseFloat(min_max.max).toFixed(selected_crypto.significant_decimals);
            }
            if (amount < min_max.min) {
                crypto_amount_input_ref.current.value = parseFloat(min_max.min).toFixed(selected_crypto.significant_decimals);
            }

            amount = parseFloat(crypto_amount_input_ref.current.value);
            butcoin_output_ref.current.value = "";

            if (selected_crypto.name != "Polygon") {
                let currency = selected_crypto.symbol.toLowerCase();

                if (override) {
                    amount = selected_crypto.default_value;
                    crypto_amount_input_ref.current.value = amount.toFixed(selected_crypto.significant_decimals);
                }

                let exchange_info = await get_crypto_exchange_amount(currency, amount);
                butcoin_output_ref.current.value = (exchange_info[0].amountTo * exchange_rate - 0.25).toFixed(2);

                let scale_factor = Math.pow(10, selected_crypto.significant_decimals);
                let max_amount = 10000 / parseFloat(exchange_info[0].rate);

                set_min_max({
                    min: Math.ceil(exchange_info[0].minFrom * scale_factor) / scale_factor,
                    max:  Math.floor(max_amount*scale_factor)/scale_factor
                });
            }
            else {
                if (override) {
                    crypto_amount_input_ref.current.value = (200).toFixed(selected_crypto.significant_decimals);

                    if (window.ethereum) {
                        butcoin_output_ref.current.value = (200 * exchange_rate).toFixed(2);
                    }
                    else {
                        butcoin_output_ref.current.value = (200 * exchange_rate - 0.25).toFixed(2);
                    }
                }
                else {
                    if (window.ethereum) {
                        butcoin_output_ref.current.value = (crypto_amount_input_ref.current.value * exchange_rate).toFixed(2);
                    }
                    else {
                        butcoin_output_ref.current.value = (crypto_amount_input_ref.current.value * exchange_rate - 0.25).toFixed(2);
                    }
                }

                set_min_max({min:5, max: 1800});
            }
        } catch (error) {}
    }
    
    function crypto_input_changed() {
        if (crypto_timeout_ref.current !== null) {
            clearTimeout(crypto_timeout_ref.current);
        }
        crypto_timeout_ref.current = setTimeout(async function() {
            trigger_event("change_crypto_currency_amount", 0);
            await set_crypto_price();
        }, 1000);
    }

    // ---- Modal Triggers ----
    const { open_modal, close_modal } = useModal();

    function select_crypto() {
        open_modal("crypto_selector", { set: set_selected_crypto });
    }

    // ---- Purchase Handler ----
    async function mint_on_contract(amount, referral_address=null_address) {
        try {

            trigger_event("init_polygon_purchase", amount);

            let result = await writeContract(wagmi_config, {
                address: contract_addresses.Butcoin,
                abi: butcoin_json.abi,
                functionName: 'mint',
                args: [address, referral_address, JSON.stringify({type: "mint", currency: "MATICPOLYGON"})],
                value: ethers.parseEther(amount.toString()),
                chainId: 137
            });

            trigger_event("success_polygon_purchase", amount);
            open_modal("payment_instructions", {amount, selected_crypto, init_index:5, hash:result});

        } catch (error) {

            if (parseInt(config.chain_id) != chain.id) {
                alert("Looks like your wallet is not on the Polygon Blockchain. Please navigate to your wallet and switch to the Polygon Blockchain.");
            }
            else {
                console.log(error);
                alert("An error has occured. Please make sure you have sufficient funds for this purchase.");
            }
            
        }
    }

    function handle_purchase() {
        if (!agreed_tos) {
            alert("You must first read and agree to our terms and conditions before continuing.")
        }
        else {

            

            if (tab == 0) {

                let amount = crypto_amount_input_ref.current.value;

                if (selected_crypto.name == "Polygon") {
                    if (window.ethereum) {
                        if (chain.id != 137) {
                            switchChain(wagmi_config, {chainId: 137});
                        }
                        else {
                            mint_on_contract(amount, referral_address);
                        }
                    }
                    else {
                        let amount_in = parseFloat(crypto_amount_input_ref.current.value);
                        trigger_event("init_manual_purchase", amount_in);
                        open_modal("payment_instructions", {
                            recipient: address,
                            referral: referral_address,
                            currency_in: selected_crypto.symbol.toLowerCase(),
                            amount_in: amount_in,
                            min_max,
                            selected_crypto,init_index:7,
                        });
                    }
                }
                else {
                    let amount_out = parseFloat(butcoin_output_ref.current.value) / 10;
                    trigger_event("init_changelly_purchase", amount_out);

                    open_modal("payment_instructions", {
                        recipient: address,
                        referral: referral_address,
                        currency_in: selected_crypto.symbol.toLowerCase(),
                        amount_in: parseFloat(crypto_amount_input_ref.current.value),
                        min_max,
                        selected_crypto,init_index:0,
                        amount_out
                    });
                }
            }
            else {
                let amount_in = parseFloat(fiat_amount_input_ref.current.value);
                trigger_event("init_credit_card_purchase", amount_in);

                open_modal("payment_instructions", {
                    recipient: address,
                    referral: referral_address,
                    currency_in: "USD",
                    amount_in: amount_in,
                    min_max,
                    country_code: country,
                    selected_crypto,init_index:6,
                    fiat_provider: fiat_providers[fiat_provider_index]
                });
            }
        }
    }

    // ---- Wallet Connection ----
    const { isConnected, address, chain }  = useAccount();
    const { connect, connectors } = useConnect();

    const injected_connector = connectors[0];

    async function connect_wallet() {
        if (window.ethereum) {
            await connect({ connector: injected_connector });
        } else {

            let amount;

            if (tab == 0) {
                amount = crypto_amount_input_ref.current.value;
            }
            else {
                amount = fiat_amount_input_ref.current.value;
            }

            let currency_index = selected_crypto.index;
            let tab_index = tab;

            trigger_event("wallet_connected", 0);

            open_modal("mobile_connector", {extra:`&extra=true&amount=${amount}&currency_index=${currency_index}&tab_index=${tab_index}`});
        }
    }

    // ---- Suggestions ----
    function set_suggestion(suggestion) {
        if (tab == 0) {
            crypto_amount_input_ref.current.value = "";
            set_selected_crypto(suggestion.currency);
            // setTimeout(()=>{
            //     crypto_amount_input_ref.current.value = parseFloat(suggestion.amount.replace(",","")).toFixed(suggestion.currency.significant_decimals);
            //     set_crypto_price();
            // },1000)
        }
        else {

        }
    }

    function set_f_provider(index) {
        set_fiat_provider_index(index);
    }

    // ---- Onloads / On Events ----
    useEffect(()=> {

        let url = new URL(window.location.href);
        let url_params = url.searchParams;

        (async function() {

            let country = await get_country();
            set_country(country);

            try {
                let is_geo_blocked = await validate_country(country);
                if (is_geo_blocked) {
                    open_modal("geo_block");
                }
            } catch (error) {
                open_modal("unknown_geo");
            }
            

            if (url_params.get("referral")) {
                let referral = url_params.get("referral");
                let referral_address = await get_active_address_by_id(referral);
                if (is_valid_wallet_address(referral_address.address)) {
                    set_referral_address(referral_address.address);
                }
                else {
                    remove_param_without_reloading("referral");
                }
            }

        })();

        if (url_params.get("try_connect") && window.ethereum && !isConnected) {
            connect_wallet();
        }

        if (url_params.get("extra")) {
            
            let amount = parseFloat(url_params.get("amount"));
            let currency_index = parseInt(url_params.get("currency_index"));
            let tab_index = parseInt(url_params.get("tab_index"));

            if (tab_index == 1) {
                set_tab(1);

                setTimeout(function() {
                    fiat_amount_input_ref.current.value = amount.toFixed(2);
                },1000);
            }
            else {
                set_selected_crypto(currencies[currency_index]);

                setTimeout(function() {
                    crypto_amount_input_ref.current.value = amount.toFixed(currencies[currency_index].significant_decimals);
                },1000);
                
            }

            setTimeout(()=>scroll_to_pannel(0),1000);
            
        }

        if (url_params.get("id") && url_params.get("type")) {
            let id = url_params.get("id");
            let type = url_params.get("type");
            open_modal("payment_instructions", {
                id,
                selected_crypto,
                init_index: type == "crypto" ? 0 : type == "fiat" ? 6 : 7
            });
        }

        (async function() {

            let supply = await get_presale_supply();
            set_cur_supply(supply);

            let liquidity = await get_liquidity();
            set_liquidity(liquidity);

            let rate = await get_exchange_rate();
            set_exchange_rate(rate);

            let dates = await get_next_price_increase();
            set_prices_dates(dates);

        })();

        (async function() {
            let response = await get_fiat_providers("USD", 200, country);
            let providers = [];
            for (let i = 0; i < response.length; i++) {
                if (!response[i].errorMessage && ["banxa","wert","transak"].includes(response[i].providerCode)) {
                    providers.push(response[i].providerCode);
                }
            }
            set_fiat_providers(providers);
        })();
            

    },[]);

    useEffect(()=> {
        (async function() {
            await set_crypto_price(true);
        })();
    }, [selected_crypto, exchange_rate]);

    useEffect(()=> {
        (async function() {
            await set_fiat_price();
        })();
    }, [fiat_provider_index]);

    useEffect(()=> {

        setTimeout(function() {
            set_fiat_price();
        },1000)

    }, [fiat_providers])

    useEffect(() => {

        if (tab == 0) {
            set_crypto_price(true);
            trigger_event("set_crypto", 0);
        }
        if (tab == 1) {
            butcoin_output_ref.current.value = "";
            fiat_amount_input_ref.current.value = "200.00";
            set_fiat_price();
            trigger_event("set_credit_card", 0);
        }

    }, [tab, exchange_rate]);

    useEffect(() => {
        if (isConnected) {
            trigger_event("wallet_connected", 0);
        }
    }, [isConnected]);

    useEffect(() =>{
        if (agreed_tos) {
            trigger_event("agree_tos", 0);
        }
    },[agreed_tos]);

    useEffect(() => {

        if (countdown_timer_ref.current !== null) {
            clearTimeout(countdown_timer_ref.current);
        }

        function get_countdown(t1, t2) {
            const diff = Math.abs(t2 - t1);

            const days = Math.floor(diff / (3600 * 24));
            const hours = Math.floor((diff % (3600 * 24)) / 3600);
            const minutes = Math.floor((diff % 3600) / 60);
            const seconds = diff % 60;
        
            const format = (num) => String(num).padStart(2, '0');

            return [format(days), format(hours), format(minutes), format(seconds)];
        }

        countdown_timer_ref.current = setInterval(function() {

            let progress_rate = (86400-Math.abs(prices_dates[1] - prices_dates[0])) / 86400;

            (async function() {
                if (prices_dates[0] > prices_dates[1]) {
                    let dates = await get_next_price_increase();
                    set_prices_dates(dates);
                }
            })();

            if (progress_rate < 0.25) {
                progress_rate = 0.25;
            }
            inner_progress_ref.current.style.width = `${progress_rate*100}%`;

            let countdown = get_countdown(prices_dates[0], prices_dates[1]);

            days_ref.current.innerHTML = countdown[0];
            hours_ref.current.innerHTML = countdown[1];
            minutes_ref.current.innerHTML = countdown[2];
            seconds_ref.current.innerHTML = countdown[3];

            set_prices_dates(
                [prices_dates[0]+1,
                prices_dates[1]]
            )

        },1000);

    }, [prices_dates]);



    return (
        <>
            <div className="central_container_box switch_hook">
                <div className="cc_top_title green_background"><h2 className="handwritten">PRESALE</h2></div>
                <div className="sw_progress_container">
                    <div className="sw_loading_text">Next Price Increase In</div>
                    <div className="white_line"></div>
                    <div className="sw_countdown_container">
                        <div className="sw_countdown_type">Days</div>
                        <div className="sw_countdown_type">Hours</div>
                        <div className="sw_countdown_type">Minutes</div>
                        <div className="sw_countdown_type">Seconds</div>
                        <div className="sw_countdown_holder" ref={days_ref}></div>
                        <div className="sw_countdown_holder" ref={hours_ref}></div>
                        <div className="sw_countdown_holder" ref={minutes_ref}></div>
                        <div className="sw_countdown_holder" ref={seconds_ref}></div>
                    </div>
                    <div className="sw_loading_bar">
                        <div className="sw_loading_inner" ref={inner_progress_ref}></div>
                    </div>
                    <div className="sw_loading_text"><b>🚀 Buy Now! Daily Price Increase 📈</b></div>
                    <div className="sw_loading_text" style={{marginTop: "-15px", fontSize: "12px"}}>{short_format_number(parseFloat(cur_supply))} $BUTT Sold!</div>

                    <div className="sw_tab_container">
                        <Clickable_Div className={`light_hover sw_tab${tab==0?" sw_tab_selected":""}`} on_click={()=>set_tab(0)}>Crypto</Clickable_Div>
                        <Clickable_Div className={`light_hover sw_tab${tab==1?" sw_tab_selected":""}`} on_click={()=>set_tab(1)}>Credit Card</Clickable_Div>
                    </div>
                    <div className="sw_main_section">
                        <div className="extra_seperator"></div>

                        { tab == 0 ?
                        <>
                            <div className="suggestion_container">
                                <div className="suggestion_inner" style={{width: `${amount_suggesions.length*95+10+15}px`}}>
                                    {
                                    amount_suggesions.map((item, index) => (
                                        <>
                                        {
                                            index == amount_suggesions.length-1 ?
                                            <div className="suggestion_item light_hover" key={index} onClick={select_crypto} style={{width: "100px"}}>
                                                <img className="suggestion_currency_icon" src={more_icon}/>
                                                <div className="suggestion_label">VIEW ALL</div>
                                            </div>
                                            :
                                            <div className="suggestion_item light_hover" key={index} onClick={()=>set_suggestion(item)}>
                                                <img className="suggestion_currency_icon" src={item.currency.icon}/>
                                                <div className="suggestion_label">{item.currency.human_readable}</div>
                                            </div>
                                        }
                                        </>
                                    ))
                                    }
                                </div>
                            </div>

                        
                            <div className={`sw_swap_container ${crypto_focused?"sw_swap_container_focused":""}`}>
                                <div className="sw_pre_label">You pay</div>
                                <input className="big_input_left" ref={crypto_amount_input_ref} onChange={crypto_input_changed} onFocus={()=>set_crypto_focused(true)} onBlur={()=>set_crypto_focused(false)} type="number" inputMode="decimal"/>
                                <div className="sw_pre_label">Min: {add_thousand_separators(parseFloat(min_max.min).toFixed(selected_crypto.significant_decimals))} / Max: {add_thousand_separators(parseFloat(min_max.max).toFixed(selected_crypto.significant_decimals))}</div>
                                <Clickable_Div className="small_currency_selector light_hover notranslate" on_click={select_crypto}>
                                    <img className="small_currency_icon" src={selected_crypto.icon}/>
                                    {selected_crypto.blockchain_icon != selected_crypto.icon && <img className="small_blockchain_icon_sw" src={selected_crypto.blockchain_icon}/>}
                                    <img className="small_drop_down_icon" src={down_icon}/>
                                    <div className="small_currency_label">{selected_crypto.human_readable}</div>
                                </Clickable_Div>
                            </div> </>:
                            <>
                            {
                                fiat_providers.length > 0 &&
                                <div className="suggestion_container">
                                    <div className="suggestion_inner" style={{width: `${fiat_providers.length*110+10}px`}}>
                                        {
                                            fiat_providers.map((item, index)=> (
                                                <>
                                                    <div className="suggestion_item light_hover" key={index} onClick={()=>set_f_provider(index)} style={{width: "100px", border: fiat_provider_index==index?"1px solid blue":"none"}}>
                                                        <img className="suggestion_currency_icon" src={fiat_icon_map[item]}/>
                                                        <div className="suggestion_label">{item}</div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            <Clickable_Div className={`sw_swap_container ${fiat_focused?"sw_swap_container_focused":""}`} on_click={()=>{
                                // fiat_amount_input_ref.current.focus();
                            }}>
                                <div className="sw_pre_label">You pay</div>
                                <input className="big_input_left" ref={fiat_amount_input_ref} onChange={fiat_input_changed} onFocus={()=>set_fiat_focused(true)} onBlur={()=>set_fiat_focused(false)} type="number" inputMode="decimal"/>
                                <div className="sw_pre_label">Min: $20.00 / Max: $5,000.00</div>
                                <div className="small_currency_selector notranslate">
                                    <img className="small_currency_icon" src={usa_icon}/>
                                    <div className="small_currency_label">$USD</div>
                                </div>
                            </Clickable_Div>
                            </>
                        }

                        <div className="extra_seperator_small"></div>

                        <Clickable_Div className="sw_swap_container" on_click={()=>{
                            if (tab==0) {
                                crypto_amount_input_ref.current.focus();
                                crypto_amount_input_ref.current.select();
                            }
                            else {
                                fiat_amount_input_ref.current.focus();
                                fiat_amount_input_ref.current.select();
                            }
                        }}>
                            <div className="sw_pre_label">You receive</div>
                            <input className="big_input_left" ref={butcoin_output_ref} readOnly/>
                            <div className="sw_pre_label">Instantly deposited to your wallet</div>
                            <div className="small_currency_selector notranslate">
                                <img className="small_currency_icon" src={butcoin_icon}/>
                                <div className="small_currency_label">$BUTT</div>
                            </div>
                        </Clickable_Div>

                        <Clickable_Div className={`tick_button_hover sw_tos_box${agreed_tos?" sw_tos_box_ticked":""}`} on_click={()=>set_agreed_tos(!agreed_tos)}>
                            { agreed_tos && <img className="sw_tos_tick_icon" src={tick_icon}/> }
                        </Clickable_Div>
                        <div className="sw_tos_text">
                            I agree to <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.terms)}><u className="clickable_text">Terms of Use</u></Clickable_Div> and <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.privacy)}><u className="clickable_text">Privacy Policy</u></Clickable_Div>. I hereby also certify that I have read in full and understood the <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.disclaimer)}><u className="clickable_text">Risks Disclaimer</u></Clickable_Div> and I am not from any <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.blocked_countries)}><u className="clickable_text">Blocked Country</u></Clickable_Div>.
                            {
                                tab==0 && selected_crypto.name != "Polygon" &&
                                <>
                                    <br/><br/>
                                    I have also read and agreed to the Changelly <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.changelly_terms)}><u className="clickable_text">Terms of Use</u></Clickable_Div>  and  <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.changelly_privacy)}><u className="clickable_text">Privacy Policy</u></Clickable_Div>. By purchasing, I acknowledge and understand that my transaction may trigger AML/KYC verification according to Changelly <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.changelly_kyc)}><u className="clickable_text">AML/KYC</u></Clickable_Div>.
                                </>
                            }
                        </div>


                        { !isConnected &&
                            <Clickable_Div className="sw_buy_button green_button_hover" on_click={connect_wallet}>
                                <b>Connect Wallet</b>
                            </Clickable_Div>   
                        }
                        { isConnected &&
                            <Clickable_Div className="sw_buy_button green_button_hover" on_click={handle_purchase}>
                                {
                                    selected_crypto.name == "Polygon" && chain && chain.id &&
                                    chain.id != config.chain_id && tab == 0 && window.ethereum ?
                                    <b>Switch Network</b> :
                                    <b>Buy Now</b>
                                }
                            </Clickable_Div>   
                        }

                        <img className="payment_methods_img" src={payment_methods}/>

                    </div>
                </div>
            </div>
        </>
    )
}


function Sales_Widget() {
    return (
        <Modal_Provider>
            <Sales_Widget_Content/>
        </Modal_Provider>
    )
}
export default Sales_Widget;