import axios from "axios";

let blocked_countries = [
    // "US",
    // "CA",
    // "KP",
    // "RU",
    // "SS",
    // "SD",
    // "SY",
    // "IR",
    // "LY",
    // "VE",
    // "UK",
    "GB",
    "CN",
    "CU",
    "IR",
    "KP",
    "SY",
    "BY",
    "SS",
    // "RU",
    "BA",
    "MM"
    // "HK"
    // GB
];

export async function get_country() {
    let respone = await axios.get("https://ipapi.co/json");
    return respone.data.country;
}

export async function validate_country(country) {
    return blocked_countries.includes(country);
}

export async function check_if_location_blocked() {
    let respone = await axios.get("https://ipapi.co/json");
    return blocked_countries.includes(respone.data.country);
}
