import { useState, useRef } from "react";
import social from "../images/illustrations/social.png";
import Clickable_Div from "./clickable_div";
import { register_email } from "../services/api";
import { is_valid_email } from "../services/helper_functions";
import external_links from "../configs/external_links.json";
import tick_icon from "../images/icons/tick.png";

function Socials() {

    let email_ref = useRef();

    let [agreed_tos, set_agreed_tos] = useState(false);


    async function add_email() {
        let email = email_ref.current.value;
        if (!agreed_tos) {
            alert("You must first read and agree to our terms of service and privacy policy.")
        }
        else if (!is_valid_email(email)) {
            alert("Please enter a valid email address");
        }
        else {
            let response = await register_email(email);
            alert("Thank you for subscribing!");
            email_ref.current.value = "";
        }
    }

    return (
        // <div className="full_background outer_flex">
            // <img className="full_background_image" src={background_8}/>
            // <div className="container_outer">
                <div className="central_container_box forced_center">
                    <div className="cc_top_title blue_background"><h2 className="handwritten">SOCIALS</h2></div>
                    <div className="standard_box_inner">
                        <div className="standard_box_text">
                            {/* <img className="standard_illustration" src={social}/> */}
                            {/* <br/><br/> */}
                            <table className="standard_table notranslate">
                            <tr>
                                <td>X (formerly Twitter)</td>
                                <td>
                                    <Clickable_Div on_click={() => window.open("https://twitter.com/ButtChainCoin")}>
                                        <u className="clickable_text">@ButtChainCoin</u>
                                    </Clickable_Div>
                                </td>
                            </tr>
                            <tr>
                                <td>Preferred Hashtag</td>
                                <td>
                                    <Clickable_Div on_click={() => window.open("https://twitter.com/search?q=%23Butcoin&src=typed_query")}>
                                        <u className="clickable_text">#ButtChain</u>
                                    </Clickable_Div>
                                </td>
                            </tr>
                            <tr>
                                <td>Contact Email</td>
                                <td>
                                    <Clickable_Div on_click={() => window.open("mailto:support@buttchain.co")}>
                                        <u className="clickable_text">support@buttchain.co</u>
                                    </Clickable_Div>
                                </td>
                            </tr>


                            </table>
                        
                        
                            <br/>
                            <input className="mail_list_input" placeholder="Email address" ref={email_ref}/>
                            <Clickable_Div className="mail_list_subscribe_button blue_background blue_button_hover" on_click={add_email}>Subscribe</Clickable_Div>
                            <br/>
                            <Clickable_Div className={`tick_button_hover sw_tos_box_lean sw_tos_box${agreed_tos?" sw_tos_box_ticked":""}`} on_click={()=>set_agreed_tos(!agreed_tos)}>
                                { agreed_tos && <img className="sw_tos_tick_icon" src={tick_icon}/> }
                            </Clickable_Div>
                            <div className="sw_tos_text sw_tos_text_lean" style={{textAlign: "left"}}>
                                I agree to <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.terms)}><u className="clickable_text">Terms of Use</u></Clickable_Div> and <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.privacy)}><u className="clickable_text">Privacy Policy</u></Clickable_Div>. I hereby also certify that I have read in full and understood the <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.disclaimer)}><u className="clickable_text">Risks Disclaimer</u></Clickable_Div> and I am not from any <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.blocked_countries)}><u className="clickable_text">Blocked Country</u></Clickable_Div>.
                            </div>
                        </div>
{/*                     
                        <div className="colored_button purple_background purple_button_hover">Buy Now</div>

                        <div className="standard_box_text">
                            "If this fails, can we blame it on autocorrect? Asking for a friend."<br/>
                            -- your autocorrect (feeling guilty)
                        </div> */}
                    </div>
                </div>
            // </div>
        // </div>
    )

}

export default Socials;