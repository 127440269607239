import { http, createConfig } from 'wagmi'
import { mainnet, polygon, bsc, avalanche } from 'wagmi/chains'
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors'

// import { getDefaultConfig } from "connectkit";

const projectId = 'b4fcdaeb8de0f73b1b573d9e1c74be79'

// export const wagmi_config = createConfig(
//   getDefaultConfig({
//     // Your dApps chains
//     chains: [mainnet, polygon],
//     transports: {
//       // RPC URL for each chain
//       [mainnet.id]: http(),
//       [polygon.id]: http(),
//     },

//     // Required API Keys
//     walletConnectProjectId: projectId,

//     // Required App Info
//     appName: "Your App Name",

//     // Optional App Info
//     appDescription: "Your App Description",
//     appUrl: "https://family.co", // your app's url
//     appIcon: "https://family.co/logo.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
//   }),
// );

// /*


export const wagmi_config = createConfig({
  chains: [mainnet, polygon, bsc, avalanche],
  connectors: [
    injected(),
    walletConnect({ projectId }),
    // metaMask(),
    // safe(),
  ],
  transports: {
    [mainnet.id]: http(),
    [polygon.id]: http(),
    [bsc.id]: http(),
    [avalanche.id]: http(),
  },
})

// */