import { useState, useEffect, useRef } from "react";
import QRCode from 'qrcode.react';
import cross_icon from "../images/icons/cross.png";
import qr_icon from "../images/icons/qr.png"
import contract_addresses from "../configs/contract_addresses.json";
import { waitForTransactionReceipt, sendTransaction, switchChain, writeContract } from "@wagmi/core";
import { get_crypto_exchange_amount, create_crypto_transaction, get_crypto_transaction_status, get_crypto_transaction, update_email, create_fiat_transaction, get_fiat_transaction, get_fiat_transaction_status, create_manual_transaction, get_manual_transaction, get_manual_transaction_status } from "../services/api";
import { currencies } from "../data/cryptos";
import { is_valid_email, append_utm_without_reloading, remove_param_without_reloading } from "../services/helper_functions";
import Clickable_Div from "../components/clickable_div";
import info_icon from "../images/icons/info.png";
import tick_icon from "../images/icons/tick.png"
import changelly_icon from "../images/icons/changelly.svg";
import wert_icon from "../images/icons/wert.svg";
import { trigger_event } from "../services/analytics";
import external_links from "../configs/external_links.json";
import { useAccount, useConnect } from 'wagmi';
import { ethers } from 'ethers';
import config from "../configs/config.json";

// ---- Configs ----
import { wagmi_config } from "../configs/wagmi_config";

let evm_currencies = [
    "ETH",
    "BNBBSC",
    "AVAX"
]

let erc_20_currencies = [
    "USDT20",
    "USDTBSC",
    "USDTAVAC",
    "USDC",
    "USDCBSC",
    "USDCARB",
    "USDCAVAC",
    "USDCMATIC",
    "DAI",
    "WBTC"
]

let currency_to_chain_id = {
    "ETH": 1,
    "BNBBSC": 56,
    "AVAX": 43114,

    "USDT20": 1,
    "USDTBSC": 56,
    "USDTAVAC": 43114,
    "USDC": 1,
    "USDCBSC": 56,
    "USDCARB": 42161,
    "USDCAVAC": 43114,
    "USDCMATIC": 137,
    "DAI": 1,
    "WBTC": 1
}

let erc_20_to_contract = {
    "USDT20": "0xdac17f958d2ee523a2206206994597c13d831ec7",
    "USDTBSC": "0x55d398326f99059ff775485246999027b3197955",
    "USDTAVAC": "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
    "USDC": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    "USDCBSC": "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    "USDCARB": "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
    "USDCAVAC": "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
    "USDCMATIC": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "DAI": "0x6b175474e89094c44da98b954eedeac495271d0f",
    "WBTC": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"
}

let erc_20_to_decimal_count = {
    "USDT20": 6,
    "USDTBSC": 6,
    "USDTAVAC": 6,
    "USDC": 6,
    "USDCBSC": 6,
    "USDCARB": 6,
    "USDCAVAC": 6,
    "USDCMATIC": 6,
    "DAI": 18,
    "WBTC": 8
}

function Deposit_Step({ basic_info, set_purchase_step }) {

    let [tab, set_tab] = useState(0);
    let [show_qr_code, set_show_qr_code] = useState(false);
    let cp_text = useRef();

    let [has_copied, set_has_copied] = useState(false);

    let { chain } = useAccount();

    // let { sendTransaction } = useSendTransaction({
    //     mode: 'prepared'
    // });

    let is_evm = false;
    let target_chain = false;
    let is_erc_20 = false;
    let target_contract = "";
    let decimal_count = 0;

    if (basic_info && basic_info.currencyFrom) {
        is_evm = evm_currencies.includes(basic_info.currencyFrom.toUpperCase());
        target_chain = currency_to_chain_id[basic_info.currencyFrom.toUpperCase()];
        is_erc_20 = erc_20_currencies.includes(basic_info.currencyFrom.toUpperCase());
        if (is_erc_20) {
            is_evm = true;
            target_contract = erc_20_to_contract[basic_info.currencyFrom.toUpperCase()];
            decimal_count = erc_20_to_decimal_count[basic_info.currencyFrom.toUpperCase()];
        }
    }
    


    async function switch_network() {
        try {
            await switchChain(wagmi_config, {chainId: target_chain});
        } catch (error) {
            console.log(error);
        }
    }

    let usdt_abi = [
        {"constant":false,"inputs":[{"name":"_to","type":"address"},{"name":"_value","type":"uint256"}],"name":"transfer","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function"}
    ]

    async function pay_in_wallet() {

        try {

            if (is_erc_20) {
                let tx = await writeContract(wagmi_config, {
                    address: target_contract,
                    abi: usdt_abi,
                    functionName: 'transfer',
                    args: [
                        basic_info.payinAddress,
                        ethers.parseUnits(basic_info.amountExpectedFrom.toString(), decimal_count)
                    ],
                    chainId: target_chain
                })
            } 
            else {
                let tx = await sendTransaction(wagmi_config, {
                    to: basic_info.payinAddress,
                    value: ethers.parseEther(basic_info.amountExpectedFrom.toString()),
                    chainId: target_chain
                });    
            }         

            
            trigger_event("success_changelly_purchase", basic_info.amount_out);
            set_purchase_step(1);

        } catch (error) {
            console.log(error);
            alert("An error has occured. Please make sure you have enough funds in your wallet to complete the purchase");
        }
    }
    

    useEffect(()=>{

        if (has_copied) {
            setTimeout(function() {
                set_has_copied(false);
            },2000);
        }

    },[has_copied])

    return (
        <>
            <div className="modal_title"><h3>Complete Purchase</h3></div> 

            {
                is_evm && window.ethereum &&
                <>
                <div className="sw_tab_container" style={{marginTop: "-20px", borderTop: "none", borderBottom: "1px solid var(--medium_gray)"}}>
                    <Clickable_Div className={`light_hover sw_tab${tab==0?" sw_tab_selected":""}`} on_click={()=>set_tab(0)}>Pay in Wallet</Clickable_Div>
                    <Clickable_Div className={`light_hover sw_tab${tab==1?" sw_tab_selected":""}`} on_click={()=>set_tab(1)}>Pay Manually</Clickable_Div>
                </div>
                <br/>

                { tab == 0 && 
                    <>
                        <h3>Pay via Your Connected Wallet</h3>
                        <br/>
                        {
                            chain.id != target_chain ?
                            <>
                                <p>It looks like you are not connected on the correct chain. Please switch blockchains to {basic_info.blockchain} using the button below or manually in your wallet app.</p>
                                <div className="copy_address_button green_button_hover" onClick={switch_network}><b>Switch Network</b></div>
                            </>:
                            <>
                                <p>Complete the purchase by clicking the button below.</p>
                                <div className="copy_address_button green_button_hover" onClick={pay_in_wallet}><b>Buy Now</b></div>
                            </>
                            
                        }
                        <br/><br/>
                        <p>Transaction Processed Via</p>
                        <br/>
                        <img src={changelly_icon}/>
                        <br/><br/>
                        <p>For any technical issues please contact us at support@buttchain.co. We usually reply very quickly.</p>
                    </>
                }
                </>
                
            }

            {
                basic_info ?
                <>
                    { (!window.ethereum || tab == 1 || !is_evm) &&
                        <>
                            <h3>Send funds to the address below</h3>
                            <br/>
                            <p>Copy the address below. Then open your crypto or exchange wallet and send funds to the address below to buy your ButtChain tokens.</p>
                            <br/>
                            <p className="gray_text" style={{fontSize: "14px"}}>Amount To Send</p>
                            <p>{basic_info.amountExpectedFrom} {basic_info.currencyFrom.toUpperCase()}</p>
                            <br/>
                            <p className="gray_text" style={{fontSize: "14px"}}>Unique Deposit Address</p>
                            <p className="break_all" ref={cp_text}>{basic_info.payinAddress}</p>
                            <p className="green_text" style={{color: "var(--medium_green)"}}><b>Blockchain: {basic_info.blockchain}</b></p>
                            <p style={{display: has_copied?"block":"none", color: "var(--medium_green)"}}><br/><b>Address Copied!</b><br/></p>
                            <Clickable_Div className="copy_address_button green_button_hover" on_click={()=>{
                                navigator.clipboard.writeText(basic_info.payinAddress);
                                
                                // const selection = window.getSelection();
                                // const range = document.createRange();
                                // range.selectNodeContents(cp_text.current);
                                // selection.removeAllRanges();
                                // selection.addRange(range);

                                set_has_copied(true);

                            }}><b>Copy Address</b></Clickable_Div>
                            <div className="warning_text_container">
                                <img className="warning_text_icon" src={info_icon}/>
                                Please note that you can send funds to the address above only once. We will automatically accept any amount between {basic_info.min_max.min} {basic_info.currencyFrom.toUpperCase()} and  {basic_info.min_max.max} {basic_info.currencyFrom.toUpperCase()}.
                            </div>
                            <br/>
                            <h4>Listening for transactions</h4>
                            <p style={{fontSize: "14px"}}>Once funds are recieved, your ButtChain will be automatically deposited into your connected wallet.</p>
                            <br/>
                            <div className="modal_loading_bar">
                                <div className="sw_loading_inner"></div>
                            </div>
                            <br/>
                            <p>Status: waiting</p>
                            <br/>
                            <p>Transaction Processed Via</p>
                            <br/>
                            <img src={changelly_icon}/>
                            <br/><br/>
                            <p>For any technical issues please contact us at support@buttchain.co. We usually reply very quickly.</p>
                        </>
                    }
                </> :
                <>
                    <h3>Loading...</h3>
                    <br/>
                    <p>Please wait</p>
                </>
            }
{/* 
            {
                show_qr_code ?
                <>
                    <h3>Scan the QR code from your wallet or exchange app</h3>
                    <br/>
                    <QRCode value={"test 13"} size={256}/>
                    <br/><br/>
                    <p>Amount</p>
                    <p>0.1 BTC</p>
                    <br/>
                    <p>Unique Deposit Address (BTC)</p>
                    <p>0x.....</p>
                    <p>Blockchain: Bitcoin</p>
                    <p>Expiration: 12 hrs 3mins 10 sec</p>
                </> :
                <>
                    <h3>Send funds to the address below</h3>
                    <br/>
                    <p>Amount</p>
                    <p>0.1 BTC</p>
                    <br/>
                    <p>Unique Deposit Address (BTC)</p>
                    <p>0x.....</p>
                    <p>Blockchain: Bitcoin</p>
                    <p>Expiration: 12 hrs 3mins 10 sec</p>
                    <div className="copy_address_button"><b>Copy Address</b></div>
                    <div className="qr_code_button" onClick={()=>set_show_qr_code(true)}>
                        <img className="qr_code_icon" src={qr_icon}/>
                    </div>
                </>
            }
            <br/><br/>
            <p>Once funds are recieved, your Butcoin will be automatically deposited into your connected wallet.</p>
            <br/>
            <p>For any technical issues please our support at support@butcoin.com. We usually reply very quickly.</p> */}
        </>
    )
}

function Payment_Processing({id, status}) {

    let email_input = useRef();
    let [allow_marketing, set_allow_marketing] = useState(true);

    async function add_email() {

        let email = email_input.current.value;

        if (!is_valid_email(email)) {
            alert("Please enter a valid email address");
        }
        else {
            await update_email(id, email, allow_marketing);
            alert("Email address successfully added. We will email you when your transaction is completed");
            email_input.current.value = "";
        }

        
    }

    return (
        <>
            <div className="modal_title"><h3>Purchase Processing</h3></div>  
            <h3>We have detected your transaction and your ButtChain is on its way!</h3>
            <br/>
            <div className="modal_loading_bar">
                <div className="sw_loading_inner"></div>
            </div>
            <br/>
            <p>Status: {status}</p>
            <br/>
            <p>You don't have to wait here. Enter your email and we will notify you when your purchase was completed.</p>
            <input ref={email_input} className="modal_email_input" placeholder="Email address"/>
            <Clickable_Div className="notify_button purple_background purple_button_hover" on_click={add_email}>Notify Me</Clickable_Div>
            <Clickable_Div className={`tick_button_hover sw_tos_box_lean sw_tos_box${allow_marketing?" sw_tos_box_ticked":""}`} on_click={()=>set_allow_marketing(!allow_marketing)}>
                { allow_marketing && <img className="sw_tos_tick_icon" src={tick_icon}/> }
            </Clickable_Div>
            <div className="sw_tos_text sw_tos_text_lean">I would like to recieve updates and marketing about the ButtChain project.</div>
            <br/><br/>
            <p>For any technical issues please contact us at support@buttchain.co. We usually reply very quickly.</p>
        </>
    )

}

function Payment_Complete() {
    return (
        <>
            <div className="modal_title"><h3>Purchase Successful</h3></div>  
            <h3>Your purchase is complete. Your ButtChain is now in your wallet</h3>
            <br/>
            <p>To view your ButtChain in your wallet please import our contract address as custom token in your wallet app according to the following details</p>
            <br/>
            <table className="standard_table">
                <tr>
                    <td>Blockchain</td>
                    <td>Polygon (MATIC)</td>
                </tr>
                <tr>
                    <td>Contract Address</td>
                    <td>{contract_addresses.Butcoin}</td>
                </tr>
                <tr>
                    <td>Token Symbol</td>
                    <td>BUTT</td>
                </tr>
                <tr>
                    <td>Token Decimals</td>
                    <td>18</td>
                </tr>
            </table>
            <br/>
            <p>A step by step guide on how to do this is <Clickable_Div className="inline_content clickable_text" on_click={()=>window.open(external_links.import_wallet)}><u>available here</u></Clickable_Div>.</p>
            <br/><br/>
            <p>For any technical issues please contact us at support@buttchain.co. We usually reply very quickly.</p>
        </>
    )
}

function KYC_Required({id}) {

    return (
        <>
            <div className="modal_title"><h3>Purchase Processing</h3></div>  
            <h3>Our payment provider Changelly is requiring that you complete KYC verification in order to complete your purchase.</h3>
            <br/>
            <p>Please contact security@changelly.com to complete your KYC in a few short steps.</p>
            <br/>
            <p>You will need to provide them with the following order id: <a className="notranslate">{id}</a></p>
        </>
    )

}

function Error_Screen({id}) {

    return (
        <>
            <div className="modal_title"><h3>Purchasing Error</h3></div>  
            <h3>An error has occured during the purchase process</h3>
            <br/>
            <p>Please contact us at support@buttchain.co and we will be in touch as soon as possible.</p>
            { id &&
                <>
                    <br/>
                    <p>You will need to provide us with the following order id: <a className="notranslate">{id}</a></p>
                </>
            }
        </>
    )

}

function Direct_To_Contract_Payment({ hash }) {

    let [status, set_status] = useState("pending");

    useEffect(() => {

        (async function() {

            try {
                let data = await waitForTransactionReceipt(wagmi_config, {hash});
                set_status("success");
            } catch (error) {
                console.log(error)
                set_status("error");
            }

        })();

    },[])

    return (
        <>
            { status == "pending" &&
                <>
                    <div className="modal_title"><h3>Purchase Processing</h3></div>  
                    <h3>We have detected your transaction and your ButtChain is on its way!</h3>
                    <br/>
                    <div className="modal_loading_bar">
                        <div className="sw_loading_inner"></div>
                    </div>
                    <br/>
                    <p>Status: Pending</p>
                </>
            }

            { status == "success" &&
                <>
                    <div className="modal_title"><h3>Purchase Successful</h3></div>  
                    <h3>Your purchase is complete. Your ButtChain is now in your wallet</h3>
                    <br/>
                    <p>To view your ButtChain in your wallet please import our contract address as custom token in your wallet app according to the following details</p>
                    <br/>
                    <table className="standard_table">
                        <tr>
                            <td>Blockchain</td>
                            <td>Polygon (MATIC)</td>
                        </tr>
                        <tr>
                            <td>Contract Address</td>
                            <td>{contract_addresses.Butcoin}</td>
                        </tr>
                        <tr>
                            <td>Token Symbol</td>
                            <td>BUTT</td>
                        </tr>
                        <tr>
                            <td>Token Decimals</td>
                            <td>18</td>
                        </tr>
                    </table>
                    <br/>
                    <p>A step by step guide on how to do this is available here.</p>
                    
                </>
            }

            { status == "error" &&
                <>
                    <div className="modal_title"><h3>Purchase Error</h3></div>  
                    <h3>An error has occured during your purchase.</h3>
                </>
            }

            <br/><br/>
            <p>For any technical issues please contact us at support@buttchain.co. We usually reply very quickly.</p>
        </>
    )
}

function Fiat_Payment({ basic_info }) {

    return (
        <>
            <div className="modal_title"><h3>Complete Purchase</h3></div>  
            {
                basic_info ?
                <>
                    <h3>Complete the checkout in the widget below</h3>
                    <br/>
                    <p>Any amount of Polygon / Matic you purchase will automatically be converted in ButtChain and deposited into your connected wallet.</p>
                    <br/>
                    <iframe src={basic_info.redirectUrl} frameBorder="0" className="iframe_payment"></iframe>
                    <br/><br/>
                    <p>Transaction Processed Via</p>
                    <br/>
                    <img src={changelly_icon}/>
                    <br/><br/>
                    {/* <p>Once funds are recieved, your Butcoin will be automatically deposited into your connected wallet.</p> */}
                    {/* <br/> */}
                    <p>For any technical issues please contact us at support@buttchain.co. We usually reply very quickly.</p>
                </> :
                <>
                    <h3>Loading...</h3>
                    <br/>
                    <p>Please wait</p>
                </>
            }   
        </>
    )

}

function Manual_Payment({ basic_info }) {

    let cp_text = useRef();
    let [has_copied, set_has_copied] = useState(false);

    useEffect(()=>{

        if (has_copied) {
            setTimeout(function() {
                set_has_copied(false);
            },2000);
        }

    },[has_copied])

    return (
        <>
            <div className="modal_title"><h3>Complete Purchase</h3></div>  
            {
                basic_info ?
                <>
                    <h3>Send funds to the address below</h3>
                    <br/>
                    <p>Copy the address below. Then open your crypto or exchange wallet and send funds to the address below to buy your ButtChain tokens.</p>
                    <br/>
                    <p className="gray_text" style={{fontSize: "14px"}}>Amount To Send</p>
                    <p>{basic_info.amountExpectedFrom} Matic (Polygon)</p>
                    <br/>
                    <p className="gray_text" style={{fontSize: "14px"}}>Unique Deposit Address</p>
                    <p className="break_all" ref={cp_text}>{basic_info.payinAddress}</p>
                    <p className="green_text" style={{color: "var(--medium_green)"}}><b>Blockchain: {basic_info.blockchain}</b></p>
                    <p style={{display: has_copied?"block":"none", color: "var(--medium_green)"}}><br/><b>Address Copied!</b><br/></p>
                    <Clickable_Div className="copy_address_button green_button_hover" on_click={()=>{
                        navigator.clipboard.writeText(basic_info.payinAddress)

                        // const selection = window.getSelection();
                        // const range = document.createRange();
                        // range.selectNodeContents(cp_text.current);
                        // selection.removeAllRanges();
                        // selection.addRange(range);

                        set_has_copied(true);

                    }}><b>Copy Address</b></Clickable_Div>
                    
                    <div className="warning_text_container">
                        <img className="warning_text_icon" src={info_icon}/>
                        Please note that you can send funds to the address above only once. We will automatically accept any amount between {basic_info.min_max.min} MATIC and  {basic_info.min_max.max} MATIC.
                    </div>
                    <br/>
                    <h4>Listening for transactions</h4>
                    <p style={{fontSize: "14px"}}>Once funds are recieved, your ButtChain will be automatically deposited into your connected wallet.</p>
                    
                    <br/>
                    <div className="modal_loading_bar">
                        <div className="sw_loading_inner"></div>
                    </div>
                    <br/>
                    <p>Status: waiting</p>
                    <br/><br/>
                    <p>For any technical issues please contact us support@buttchain.co. We usually reply very quickly.</p>
                </> :
                <>
                    <h3>Loading...</h3>
                    <br/>
                    <p>Please wait</p>
                </>
            }
        
        </>
    );
}

function Payment_Instructions({ close_modal, parameters }) {

    // parameters.init_index = 1;

    let interval = useRef(null);
    let fiat_interval = useRef(null);
    let manual_interval = useRef(null);
    let [purchase_step, set_purchase_step] = useState(parameters.init_index);
    let [basic_info, set_basic_info] = useState(false);
    let [status, set_status] = useState("pending")

    function clear_interval() {
        if (interval.current != null) {
            clearInterval(interval.current);
            interval.current = null;
        }
    }

    function clear_fiat_interval() {
        if (fiat_interval.current != null) {
            console.log("CLEAR")
            clear_interval(fiat_interval.current);
            fiat_interval.current = null;
        }
    }

    function clear_manual_interval() {
        if (manual_interval.current != null) {
            console.log("CLEAR")
            clear_interval(manual_interval.current);
            manual_interval.current = null;
        }
    }
    
    useEffect(()=>{

       

        function update_status(new_status) {
            console.log({new_status})

            if (["confirming","exchanging","sending"].includes(new_status)) {
                trigger_event("success_changelly_purchase", parameters.amount_out);
                set_purchase_step(1);
            }
            else if (new_status == "finished") {
                set_purchase_step(2);
                clear_interval();
            }
            else if (new_status == "hold") {
                set_purchase_step(3);
                clear_interval();
            }
            else if (["failed","refunded","overdue","expired"].includes(new_status)) {
                set_purchase_step(4);
                clear_interval();
            }

            set_status(new_status);
        }

        function set_fiat_status(new_status) {
            if (new_status == "complete") {
                trigger_event("success_credit_card_purchase", parameters.amount_in);
                set_purchase_step(2);
                clear_fiat_interval()
            }
            else if (["failed","refunded","hold","expired"].includes(new_status)) {
                set_purchase_step(4);
                clear_fiat_interval();
            }
        }

        function set_manual_status(new_status) {
            if (new_status == "complete") {
                trigger_event("success_manual_purchase", parameters.amount_in);
                set_purchase_step(2);
                clear_manual_interval()
            }
            else if (["failed","refunded","hold","expired"].includes(new_status)) {
                set_purchase_step(4);
                clear_manual_interval();
            }
        }

        (async function() {

            console.log({parameters});

            if (parameters.init_index == 0) {

                try {

                    if (!parameters.id) {
                        let { recipient, referral, currency_in, amount_in } = parameters;
                        let response = await create_crypto_transaction(recipient, referral, currency_in, amount_in);
                        response.blockchain = (currencies.find(cur => cur.symbol.toUpperCase() === response.currencyFrom.toUpperCase())).blockchain_info;
                        response.min_max = parameters.min_max;
                        set_basic_info(response);
                        console.log(response);

                        parameters.id = response.id;
                        append_utm_without_reloading(`id=${parameters.id}`);
                        append_utm_without_reloading(`type=crypto`);
                        
                    }
                    else {
                        let response = await get_crypto_transaction(parameters.id);
                        response = response[0];
                        let currency = (currencies.find(cur => cur.symbol.toUpperCase() === response.currencyFrom.toUpperCase()));
                        response.blockchain = currency.blockchain_info;

                        console.log(response.currencyFrom, response.amountExpectedFrom);
                        let exchange_info = await get_crypto_exchange_amount(response.currencyFrom, currency.default_value);
                        let max_amount = 10000 / parseFloat(exchange_info[0].rate);
                        response.min_max = {
                            min: parseFloat(exchange_info[0].minFrom).toFixed(currency.significant_decimals),
                            max: max_amount.toFixed(currency.significant_decimals) // parseFloat(exchange_info[0].maxFrom).toFixed(currency.significant_decimals)
                        }
                        console.log(response);
                        set_basic_info(response);
                        update_status(response.status);
                    }

                    interval.current = setInterval(async function() {

                        if (interval.current == null) { return; }

                        let response = await get_crypto_transaction_status(parameters.id);
                        update_status(response);

                    },10000);

                } catch (error) {
                    console.log(error);
                    clear_interval();
                    set_purchase_step(4);
                }
            }

            if (parameters.init_index == 6) {

                try {

                    if (!parameters.id) {
                        let { recipient, referral, currency_in, amount_in, country_code, fiat_provider } = parameters;
                        let response = await create_fiat_transaction(recipient, referral, currency_in, amount_in, country_code, fiat_provider);
                        set_basic_info(response)

                        parameters.id = response.orderId;

                        append_utm_without_reloading(`id=${parameters.id}`);
                        append_utm_without_reloading(`type=fiat`);
                    }
                    else {
                        let { id } = parameters;
                        let response = await get_fiat_transaction(id);
                        console.log(response);

                        set_basic_info(response);
                    }

                    fiat_interval.current = setInterval(async function() {

                        if (fiat_interval.current == null) { return; }

                        let response = await get_fiat_transaction_status(parameters.id);
                        console.log(response);
                        set_fiat_status(response);

                    },10000);
                } catch (error) {
                    console.log(error);
                    clear_fiat_interval();
                    set_purchase_step(4);
                }

            }

            if (parameters.init_index == 7) {

                try {

                    if (!parameters.id) {

                        let { recipient, referral, amount_in } = parameters;
                        let response = await create_manual_transaction(recipient, referral, amount_in);;
                        response.min_max = parameters.min_max;
                        set_basic_info(response);
                        console.log(response);

                        parameters.id = response.id;
                        append_utm_without_reloading(`id=${parameters.id}`);
                        append_utm_without_reloading(`type=manual`);

                    }
                    else {
                        let { id } = parameters;
                        let response = await get_manual_transaction(id);
                        response.min_max = {
                            min: 5,
                            max: 10000
                        };
                        console.log(response);

                        set_basic_info(response);
                    }

                    manual_interval.current = setInterval(async function() {

                        if (manual_interval.current == null) { return; }

                        let response = await get_manual_transaction_status(parameters.id);
                        console.log(response);
                        set_manual_status(response);

                    },10000);

                } catch (error) {
                    console.log(error);
                    clear_manual_interval();
                    set_purchase_step(4);
                }
            }

        })()

    },[]);

    // create_fiat_transaction(recipient, referral, currency_in, amount_in, country_code)

    return (
        <>
            <Clickable_Div on_click={() => {
                clear_interval();
                remove_param_without_reloading("id");
                remove_param_without_reloading("type");
                close_modal();
            }}>
                <img className="close_modal" src={cross_icon}/>
            </Clickable_Div>
            { purchase_step == 0 &&
                <Deposit_Step basic_info={basic_info} set_purchase_step={set_purchase_step}/>
            }
            { purchase_step == 1 &&
                <Payment_Processing status={status} id={parameters.id}/>
            }
            { purchase_step == 2 &&
                <Payment_Complete/>
            }
            { purchase_step == 3 &&
                <KYC_Required id={parameters.id}/>
            }
            { purchase_step == 4 &&
                <Error_Screen id={parameters.id}/>
            }
            { purchase_step == 5 &&
                <Direct_To_Contract_Payment hash={parameters.hash}/>
            }
            { purchase_step == 6 &&
                <Fiat_Payment basic_info={basic_info}/>
            }
            { purchase_step == 7 &&
                <Manual_Payment basic_info={basic_info}/>
            }
        </>
    )

}


export default Payment_Instructions;