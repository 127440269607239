import { useState, useEffect, useRef } from "react";
import Clickable_Div from "../components/clickable_div";
import external_links from "../configs/external_links.json";
import social from "../images/illustrations/hero_2.png";

function Geo_Block({ close_modal, parameters }) {

    return (
        <>
            {/* <img className="close_modal" src={cross_icon} onClick={close_modal}/> */}
            <div className="modal_title"><h3>Geo Block</h3></div>
            <h3>ButtChain may be unavailable in your country</h3>
            <br/>
            <p>We have detected that you may be attempting to access our website from a country where it is unavailable.</p><br/>
            <img className="standard_illustration" src={social}/>
            <br/><br/>
            
            <p style={{fontSize: "12px"}}>If this is an error you may continue to our site. However, please note that by continuing you hereby certify that you are not a citizen or resident of the United States of America, Canada, the UK, or any banned country. A complete list of banned countries is <Clickable_Div className="inline_content clickable_text" on_click={()=>window.open(external_links.blocked_countries)}><u>available here</u></Clickable_Div>.</p>
            <Clickable_Div className="other_button other_button_first green_background green_button_hover" style={{width: "calc(100% - 6px)"}} on_click={()=>{
                close_modal();
            }}><b>Continue</b></Clickable_Div>
        </>
    )

}


export default Geo_Block;