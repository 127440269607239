import butcoin_text from "../images/icons/butcoin_text.png";
import hero from "../images/illustrations/hero.png";
import proof_of_butt from "../images/illustrations/proof_of_butt.png";
import hero_2 from "../images/illustrations/hero_2.png";
import hero_3 from "../images/illustrations/hero_3.png";
import social from "../images/illustrations/social.png";

let images = [
    hero,
    proof_of_butt,
    hero_2,
    hero_3,
    social
];

let descriptions = [
    <p>
    <h2>The First Auto-Liquidity Meme Coin on Polygon</h2>
    </p>,
    <p>
        Wen Marketing? Unlike other coins, ButtChain's marketing is decentralized. Anyone can make a link and earn free crypto by promoting ButtChain.
    {/* Unlike traditional cryptocurrencies that use Proof of Work (PoW) or Proof of Stake (PoS), ButtChain operates on the innovative Proof of Butt (PoB) mechanism. */}
    </p>,
    <p>
    Built by degens and audited by pros, ButtChain's tokenomics are playful and creative.
    </p>,
    <p>
    Wether you're a noob or a pro, the sections below will help answer some of the most common questions and help topics about ButtChain.
    </p>,
    <p>
    Eager to share this awesome new Meme Coin? Find us on social media and let's get the party started!
    </p>
]

function Extra_Section({index, opacity}) {
    return (
        <>
            <img className="butcoin_text_logo hidable_0" src={butcoin_text} style={{opacity}}/>

            <div className="full_width_extra_container hidable_1" style={{opacity}}>
                <img className="standard_illustration" src={images[index]}/>
                {descriptions[index]}
            </div>
        </>
    )
}

export default Extra_Section;