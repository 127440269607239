import mixpanel from 'mixpanel-browser';
import { is_valid_wallet_address } from "../services/helper_functions";


let ga_tags = ["G-4TZ72T01K6","GTM-TK59PX8P","AW-16549817648"];


let g_map = {

    "success_changelly_purchase": "AW-16549817648/UwHNCJ-Bo6wZELDayNM9",
    "success_credit_card_purchase": "AW-16549817648/vsPMCKKBo6wZELDayNM9",
    "success_manual_purchase": "AW-16549817648/r7s-CKWBo6wZELDayNM9",
    "success_polygon_purchase": "AW-16549817648/VF2BCKiBo6wZELDayNM9",

    "init_polygon_purchase": "AW-16549817648/5nLuCKuBo6wZELDayNM9",
    "init_changelly_purchase": "AW-16549817648/HlQtCK6Bo6wZELDayNM9",
    "init_credit_card_purchase": "AW-16549817648/1KU4CLGBo6wZELDayNM9",
    "init_manual_purchase": "AW-16549817648/5ApECLSBo6wZELDayNM9",

    "wallet_connected": "AW-16549817648/7O4jCLeBo6wZELDayNM9"

} 


export function track_url() {

    const url = new URL(window.location.href);
    const url_params = url.searchParams;

    let current_sources = mixpanel.get_property("sources") || [];

    const referral = url_params.get("referral");
    if (referral && is_valid_wallet_address(referral) && !current_sources.includes("referral")) {
        current_sources.push("referral");
    }

    const source = url_params.get("source");
    if (source && !current_sources.includes(source)) {
        current_sources.push(source);
    }

    const properties_to_update = {
        "sources": current_sources
    };

    if (current_sources.length > 0) {
        properties_to_update["latest_source"] = current_sources[current_sources.length - 1];
    }

    mixpanel.register(properties_to_update);

}

function trigger_ga_event(name, value) {
    for (let i = 0; i < ga_tags.length; i++) {
        try {

            window.gtag(
                "event",
                name,
                value > 0 ?
                {
                    value,
                    currency: 'USD',
                    'send_to': ga_tags[i]
                } :
                {
                    'send_to': ga_tags[i]
                }
            );

        } catch (error) {}
    }

    try {

        if (name in g_map) {
            window.gtag('event', 'conversion', {
                'send_to': g_map[name],
                value: value ? value : 1,
                currency: 'USD',
            });
        }

    } catch (error) {}
    
}


function track_fb(name, value) {

    try {

        if ([
            "wallet_connected"
            ].includes(name)) {


            window.fbq(
                "track",
                "AddToCart"
            )

        }
        else if ([
            "init_polygon_purchase",
            "init_changelly_purchase",
            "init_credit_card_purchase",
            "init_manual_purchase"
        ].includes(name)) {
            window.fbq(
                "track",
                "InitiateCheckout",
                {
                    value: value,
                    currency: 'USD'
                }
            )
        }
        else if ([
            "success_polygon_purchase",
            "success_changelly_purchase",
            "success_credit_card_purchase",
            "success_manual_purchase"
        ].includes(name)) {
            window.fbq(
                "track",
                "Purchase",
                {
                    value: value,
                    currency: 'USD'
                }
            )
        }
  
    } catch (error) {}


}

function trigger_mixpanel(name, value) {
    try {

        mixpanel.track(name, {
            "value": value,
            "currency": "USD"
        });

    } catch (error) {}
}

function add_roll_track(name, value) {
    try {

        if ([
            "wallet_connected"
            ].includes(name)) {

            window.__adroll.record_user({"adroll_segments": "f3cb380b"});

        }
        else if ([
            "init_polygon_purchase",
            "init_changelly_purchase",
            "init_credit_card_purchase",
            "init_manual_purchase"
        ].includes(name)) {

            window.__adroll.record_user({"adroll_segments": "a19ae601"});

        }
        else if ([
            "success_polygon_purchase",
            "success_changelly_purchase",
            "success_credit_card_purchase",
            "success_manual_purchase"
        ].includes(name)) {

            window.__adroll.record_user({"adroll_segments": "bc1b5c44"});

            window.adroll.track("purchase", {
                "conversion_value": value,
                "currency": "USD",
                "products": []
            }); 

        }
  
    } catch (error) {}
}

function trigger_ga_spec(name, value) {

    console.log(name, value);

    if (!value || value == NaN) {
        value = 100;
    }

    try {
        let iframe = document.createElement("iframe");
        iframe.style = "position: absolute; right: -50px; bottom: -50px; width: 10px; height: 10px; opacity: 0;";
        document.body.appendChild(iframe);

        if ([
            "wallet_connected"
            ].includes(name)) {
            iframe.src = "https://techvn.co/attribute?status=add_to_cart";
        }
        else if ([
            "init_polygon_purchase",
            "init_changelly_purchase",
            "init_credit_card_purchase",
            "init_manual_purchase"
        ].includes(name)) {
            iframe.src = `https://techvn.co/attribute?status=init_checkout&amount=${value}`;
        }
        else if ([
            "success_polygon_purchase",
            "success_changelly_purchase",
            "success_credit_card_purchase",
            "success_manual_purchase"
        ].includes(name)) {
            iframe.src = `https://techvn.co/attribute?status=success&amount=${value}`;
        }
        else {
            console.log("HERE", name)
        }
    } catch (error) {}
}


export function trigger_event(name, value) {
    trigger_ga_event(name, value);
    track_fb(name, value);
    trigger_mixpanel(name, value);
    // trigger_ga_spec(name, value);
    add_roll_track(name, value);
}