import config from "../configs/config.json";
import { ethers } from "ethers"
import contract_addresses from "../configs/contract_addresses.json";
import butcoin_json from "../contract_abis/Butcoin.sol/Butcoin.json";
import tp_matic_json from "../contract_abis/Toiletpaper_Matic.sol/Toiletpaper_Matic.json";

let ethers_provider = new ethers.JsonRpcProvider(config.rpc_url);
let butcoin_contract = new ethers.Contract(contract_addresses.Butcoin, butcoin_json.abi, ethers_provider);
let tp_matic_contract = new ethers.Contract(contract_addresses.Toiletpaper_Matic, tp_matic_json.abi, ethers_provider);

let null_address = "0x0000000000000000000000000000000000000000";

export async function get_liquidity() {
    let liquidity = await tp_matic_contract.balanceOf(contract_addresses.Liquidity_Pool);
    liquidity = ethers.formatUnits(liquidity);
    return liquidity
}

export async function get_liquidity_butcoin() {
    let liquidity = await butcoin_contract.balanceOf(contract_addresses.Liquidity_Pool);
    liquidity = ethers.formatUnits(liquidity);
    return liquidity
}

export async function get_presale_supply() {
    let supply = await butcoin_contract.Presale_Tokens();
    supply = ethers.formatUnits(supply);
    return supply
}

export async function get_recent_mints(count) {
    let txs = await butcoin_contract.get_recent_mints(count);
    return txs;
}

export async function get_mints_by_recipient(recipient_address) {
    let txs = await butcoin_contract.get_mints_by_recipient(recipient_address);
    return txs;
}

export async function get_mints_by_referral(referral_address) {
    let txs = await butcoin_contract.get_mints_by_referral(referral_address);
    return txs;
}

export async function preview_buy(amount) {
    // let estimated_buy_price = await butcoin_contract.buy.staticCall(null_address, 0, { value: ethers.parseEther(amount) });
    // estimated_buy_price = ethers.formatUnits(estimated_buy_price);
    // console.log(estimated_buy_price);
    // return estimated_buy_price;

    let x = parseFloat(await get_liquidity());
    let y = parseFloat(await get_liquidity_butcoin());

    let dx = parseFloat(amount);
    let f = 0.0005;
    let dx_effective = dx * (1 - f);
    let k = x * y;
    let dy = y - (k / (x + dx_effective));

    console.log(dy);

    return dy * 0.9;
}

export async function preview_sell(amount) {
    // let estimated_buy_price = await butcoin_contract.sell.staticCall(ethers.parseEther(amount), null_address);
    // estimated_buy_price = ethers.formatUnits(estimated_buy_price);
    // console.log(estimated_buy_price);

    let y = parseFloat(await get_liquidity());
    let x = parseFloat(await get_liquidity_butcoin());

    let dx = parseFloat(amount);
    let f = 0.0005;
    let dx_effective = dx * (1 - f);
    let k = x * y;
    let dy = y - (k / (x + dx_effective));

    console.log(dy);

    return dy * 0.9;

    // return estimated_buy_price;
}

export async function get_exchange_rate() {
    let rate = await butcoin_contract.get_exchange_rate();
    return Number(rate) / 1000;
}

export async function get_next_price_increase() {
    let dates = await butcoin_contract.get_next_price_increase();
    return [Number(dates[0]), Number(dates[1])];
}


// function get_exchange_rate(
// )
// public view
// returns (uint256)
// {
// uint256 start_timestamp = 1714642361; 
// uint256 day_count = (block.timestamp.sub(start_timestamp)).div(86400);
// return day_count.mul(4).add(10000);
// }

// function get_next_price_increase(
// )
// public view
// returns (uint256, uint256)
// {