import { useState, useEffect, useRef } from "react";
import { currencies } from "../data/cryptos";
import cross_icon from "../images/icons/cross.png";
import Clickable_Div from "../components/clickable_div";
import right_icon from "../images/icons/right.png";

let sample_icon = "https://cdn.changelly.com/icons/btc.png"



function Crypto_Selector({ close_modal, parameters }) {

    console.log(parameters);

    let search_input = useRef();

    let [searchable_currencies, set_searchable_currencies] = useState([...currencies]);

    function search() {
        let search_query = search_input.current.value.toLowerCase();

        let filtered_items = currencies.filter((item) => {
            return (
                item.name.toLowerCase().startsWith(search_query) ||
                item.symbol.toLowerCase().startsWith(search_query)
            );
        });

        set_searchable_currencies(filtered_items); 
    }

    function choose_currency(currency) {
        parameters.set(currency);
        close_modal();
    }


    return (
        <>
            <Clickable_Div on_click={close_modal}>
                <img className="close_modal" src={cross_icon}/>
            </Clickable_Div>
            <div className="modal_title"><h3>Choose Cryptocurrency</h3></div>
            <input ref={search_input} className="currency_search_bar" placeholder="Search cryptocurrency" onChange={search}/>
            <div className="currency_list_container">
                {
                    searchable_currencies.map((currency, index) => (
                        <Clickable_Div className="language_item light_hover" on_click={()=>choose_currency(currency)}>
                            <img className="live_sales_icon" src={currency.icon}/>
                            {currency.blockchain_icon != currency.icon && <img className="small_blockchain_icon" src={currency.blockchain_icon}/>}
                            <div className="live_sales_title notranslate"><b>{currency.human_readable}</b></div>
                            <div className="live_sales_desc notranslate">{currency.name}</div>
                            {/* <div className="live_sales_timestamp">{currency.fee}</div> */}
                            {/* <div className="currency_source_indicator">{currency.source}</div> */}
                            <img className="language_item_right_icon" src={right_icon}/>
                        </Clickable_Div>
                    ))
                }
            </div>
        </>
    )

}


export default Crypto_Selector;