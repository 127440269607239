import React, { createContext, useContext, useState, useEffect } from "react";
import Crypto_Selector from "./crypto_selector";
// import Fiat_Selector from "./fiat_selector";
import Payment_Instructions from "./payment_instructions";
import Language_Selector from "./language_selector";
import Referrals from "./referrals";
import Sales_Tracking from "./sales_tracking";
import Referral_Warning from "./referral_warning";
import Unknown_Geo from "./unknown_geo";
import Mobile_Connector from "./mobile_connector";
// import { check_if_location_blocked } from "../services/location";
import Geo_Block from "./geo_block";

const ModalContext = createContext();

export function useModal() {
    return useContext(ModalContext);
}

export function Modal_Provider({ children }) {

    let [show_modal, set_show_modal] = useState(false);
    let [modal_type, set_modal_type] = useState("");
    let [parameters, set_parameters] = useState(null);
    let [geo_block, set_geo_block] = useState(false);
    let [prev_offset, set_prev_offset] = useState(0);

    let open_modal = (type, modal_parameters) => {
        set_prev_offset(window.pageYOffset);
        window.scrollTo(0, 0);
        document.body.style.position = 'fixed';
        document.body.style.overflow = 'hidden';
        document.body.style.width = '100%';

        set_show_modal(true);
        set_modal_type(type);
        set_parameters(modal_parameters);
    };

    let close_modal = () => {

        document.body.style.position = '';
        document.body.style.overflow = '';
        document.body.style.width = '';
        window.scrollTo(0, prev_offset);

        set_show_modal(false);
    };

    let context_value = {
        open_modal,
        close_modal
    };

    useEffect(()=>{
        (async function() {
            // let is_blocked = await check_if_location_blocked();
            // set_geo_block(is_blocked);
        })();
    },[]);

    return (
        <ModalContext.Provider value={context_value}>
            {children}
            { geo_block && 
                <div className="modal_background">
                    <div className="modal_container">
                        <Geo_Block close_modal={close_modal}/>
                    </div>
                </div>
            }
            { show_modal &&
                (
                    <div className="modal_background">
                        <div className="modal_container">
                        {
                        modal_type == "crypto_selector" &&
                        <Crypto_Selector close_modal={close_modal} parameters={parameters}/>
                        }
                        {/* {
                        modal_type == "fiat_selector" &&
                        <Fiat_Selector close_modal={close_modal} parameters={parameters}/>
                        } */}
                        {
                        modal_type == "payment_instructions" &&
                        <Payment_Instructions close_modal={close_modal} parameters={parameters}/>
                        }
                        {
                        modal_type == "language_selector" &&
                        <Language_Selector close_modal={close_modal} parameters={parameters}/>
                        }
                        {
                        modal_type == "referrals" &&
                        <Referrals close_modal={close_modal} parameters={parameters}/>
                        }
                        {
                        modal_type == "sales_tracking" &&
                        <Sales_Tracking close_modal={close_modal} parameters={parameters}/>
                        }
                        {
                        modal_type == "geo_block" &&
                        <Geo_Block close_modal={close_modal}/>
                        }
                        {
                        modal_type == "referral_warning" &&
                        <Referral_Warning close_modal={close_modal} parameters={parameters}/>
                        }
                        {
                        modal_type == "unknown_geo" &&
                        <Unknown_Geo close_modal={close_modal} parameters={parameters}/>
                        }
                        {
                        modal_type == "mobile_connector" &&
                        <Mobile_Connector close_modal={close_modal} parameters={parameters}/>
                        }
                        </div>
                    </div>
                )
            }
        </ModalContext.Provider>
    )
}