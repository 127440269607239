function validate_touch(event) {
    const rect = event.target.getBoundingClientRect();
    const touch = event.changedTouches[0];
  
    const isInsideButton = (
      touch.clientX >= rect.left &&
      touch.clientX <= rect.right &&
      touch.clientY >= rect.top &&
      touch.clientY <= rect.bottom
    );
  
    return isInsideButton;
}

function Clickable_Div({ children, className, ref, style, set_is_hovering=function(){}, on_click=function(){} }) {

    const user_agent = navigator.userAgent || navigator.vendor;

    // if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(user_agent.toLowerCase())) {

    //     return (
    //         <div
    //             className={className}
    //             ref={ref}
    //             style={style}
    //             onTouchEnd={(e) => {
    //                 if (validate_touch(e)) {
    //                     e.preventDefault();
    //                     on_click();
    //                 }
    //             }}
    //         >
    //             {children}
    //         </div>
    //     )

    // }

    return (
        <div
            className={className}
            ref={ref}
            style={style}
            onMouseEnter={() => set_is_hovering(true)}
            onMouseLeave={() => set_is_hovering(false)}
            onClick={on_click}
        >
            {children}
        </div>
    )

}

export default Clickable_Div;