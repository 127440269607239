import { useState, useEffect, useRef } from "react";

import right_icon from "../images/icons/right.png";
import { languages } from "../data/languages";
import cross_icon from "../images/icons/cross.png";
import { initialize_translator, set_translation } from "../services/language";
import Clickable_Div from "../components/clickable_div";

let sample_icon = "https://cdn.changelly.com/icons/btc.png"



function Language_Selector({ close_modal, parameters }) {

    console.log(parameters);

    let [searchable_languages, set_searchable_languages] = useState([...languages]);

    let search_input = useRef();



    function search() {
        let search_query = search_input.current.value.toLowerCase();

        let filtered_items = languages.filter((item) => {
            return (
                item.name.toLowerCase().startsWith(search_query) ||
                item.iso_code.toLowerCase().startsWith(search_query)
            );
        });

        set_searchable_languages(filtered_items); 
    }

    async function choose_lang(language) {
        parameters.set(language);

        console.log(language);

        (async function() {
            await initialize_translator();
            await set_translation(language.iso_code);
        })();

        // new window.google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');

        // // setTimeout(()=>{
        // //     let translate_object = document.querySelector(".skiptranslate");
        // //     translate_object.style = "position: fixed; width: 0px; opacity: 0;";
        // // },100);

        // // setTimeout(()=>{
        // //     if (window.google && window.google.translate && window.google.translate.TranslateElement) {
        // //         const element = document.getElementById('google_translate_element');
        // //         const instance = element.querySelector(':scope > .goog-te-gadget');
        // //         if (instance) {
        // //         const selectElement = instance.querySelector('select.goog-te-combo');
        // //         if (selectElement) {
        // //             selectElement.value = language.iso_code;
        // //             selectElement.dispatchEvent(new Event('change'));
        // //         }
        // //         }
        // //     }
        // // },50);


        
        close_modal();
    }


    return (
        <>
            <Clickable_Div on_click={close_modal}>
                <img className="close_modal" src={cross_icon}/>
            </Clickable_Div>
            <div className="modal_title"><h3>Choose Language</h3></div>
            <input ref={search_input} className="currency_search_bar" placeholder="Search language" onChange={search}/>
            <div className="currency_list_container">
                {
                    searchable_languages.map((language, index) => (
                        <Clickable_Div className="language_item light_hover notranslate" on_click={()=>choose_lang(language)}>
                            <img className="live_sales_icon" src={language.icon}/>
                            <div className="language_item_title"><b>{language.native_name}</b></div>
                            <img className="language_item_right_icon" src={right_icon}/>
                        </Clickable_Div>
                    ))
                }
            </div>
        </>
    )

}


export default Language_Selector;