import background_3 from "../images/backgrounds/background_3.png";
import booty_guard from "../images/illustrations/booty_guard.png";
import bubble_but from "../images/illustrations/bubble_but.png";
import rear_reserve from "../images/illustrations/rear_reserve.png";
import { scroll_to_pannel, nudge } from "../services/navigation";
import Clickable_Div from "./clickable_div";

function Key_Features() {

    return (
        // <div className="full_background outer_flex">
            // <img className="full_background_image" src={background_3}/>
            // <div className="container_outer">
                <div className="central_container_box forced_center">
                    <div className="cc_top_title purple_background"><h2 className="handwritten">KEY FEATURES</h2></div>
                    <div className="standard_box_inner">
                        <div className="standard_box_text">

                            <div className="feature_container">
                                <img className="feature_img" src={bubble_but}/>
                                <div className="feature_text">
                                    <h3>Auto Liquidity</h3><br/>
                                    <p>During the presale, 20% of the presale sales is automatically deposited into Uniswap upon each purchase via the ButtChain smart contract. This ensures that there is liquidity at launch for trading.</p>
                                </div>
                            </div>

                            <div className="feature_container">
                                <img className="feature_img" src={rear_reserve}/>
                                <div className="feature_text">
                                    <h3>Liquidity Farming</h3><br/>
                                    <p>Every swap transaction with ButtChain on the ButtChain liquidity pool incurs a 5% fee, which is allocated to Uniswap liquidity, ButtChain creators, and token burning.</p>
                                </div>
                            </div>

                            <div className="feature_container">
                                <img className="feature_img" src={booty_guard}/>
                                <div className="feature_text">
                                    <h3>Direct To Contract</h3><br/>
                                    <p>Unlike most other presales, you immediately receive your ButtChain tokens upon purchase. However, ButtChain tokens can only be traded and transferred once the presale is over. This avoids a rush for token claims at launch.</p>
                                </div>
                            </div>

                        </div>

                        <Clickable_Div className="colored_button purple_background purple_button_hover" on_click={()=>{scroll_to_pannel(0); nudge(0);}}>Buy Now</Clickable_Div>

                        <div className="standard_box_text">
                            "I'll buy your stupid crypto if you stop posting pics of your butt on social media"<br/><br/>
                            — your friends (if you still have any)
                        </div>

                    </div>
                </div>
            // </div>
        // </div>
    )

}

export default Key_Features;