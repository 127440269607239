import { useState, useEffect, useRef } from "react";
import { get_mints_by_referral } from "../services/web3_getters";
import { useAccount, useConnect, useNetwork } from 'wagmi';
// import { useWeb3Modal } from '@web3modal/react';
import Clickable_Div from "../components/clickable_div";
import { currencies } from "../data/cryptos";
import { ethers } from "ethers";
// import usa_icon from "../images/icons/usa.png";
import { short_format_number, format_timestamp, format_dmy, shorten_crypto_string } from "../services/helper_functions";
import butcoin_icon from "../images/icons/butcoin.png";
import cross_icon from "../images/icons/cross.png";
import credit_card from "../images/illustrations/credit_card.png";

let usd = {
    name: "Credit Card",
    symbol: "USD",
    icon: credit_card,
    fee: "",
    source: "",
    default_value: 100,
    blockchain_info: "",
    significant_decimals: 2
}

function Referrals({ close_modal, parameters }) {

    const { isConnected, address }  = useAccount();
    // const { connect, connectors } = useConnect();

    // const injected_connector = connectors[1];
    // const web3Modal = useWeb3Modal();

    // async function handle_connect() {
    //     if (window.ethereum) {
    //         await connect({ connector: injected_connector });
    //     } else {
    //         await web3Modal.open();
    //     }
    // }

    let [transactions, set_transactions] = useState([]);
    let [totals, set_totals] = useState({butcoin: 0, matic: 0});

    useEffect(() => {

        if (isConnected) {
            (async function() {
                let txs = await get_mints_by_referral(address);
                console.log(txs);

                let total_butcoin = 0;
                let total_matic = 0;

                let transactions = [];

                for (let i = 0; i < txs.length; i++) {
                    let tx = txs[i];
                    let metadata = JSON.parse(tx.metadata);

                    let currency;

                    if (metadata.currency == "POLYGONMATIC") {
                        metadata.currency = "MATICPOLYGON";
                    }

                    if (metadata.currency.toUpperCase() == "USD") {
                        currency = usd;
                    }
                    else {
                        currency = currencies.find(cur => cur.symbol.toUpperCase() === metadata.currency.toUpperCase());
                    }     

                    if (!currency) {
                        currency = currencies[0];
                    }

                    let transaction = {
                        type: metadata.type == "mint" ? "Presale Mint" : "Uniswap",
                        amount: ethers.formatUnits(tx.butcoin_amount),
                        referral_amount: ethers.formatUnits(tx.referral_amount),
                        currency,
                        timestamp: parseInt(tx.timestamp),  
                    }

                    if (metadata.type == "mint") {
                        total_matic += parseFloat(transaction.referral_amount);
                    }
                    else {
                        total_butcoin += parseFloat(transaction.referral_amount);
                    }

                    console.log(transaction)

                    transactions.push(transaction)
                }

                set_transactions(transactions);
                set_totals({
                    butcoin: total_butcoin,
                    matic: total_matic
                })

            })();
        }

    },[isConnected]);

    return (
        <>
            <Clickable_Div on_click={close_modal}>
                <img className="close_modal" src={cross_icon}/>
            </Clickable_Div>
            {/* <img className="close_modal" src={cross_icon} onClick={close_modal}/> */}
            <div className="modal_title"><h3>Referrals</h3></div>
            <h3>Track referrals</h3>
            <br/>

            { !isConnected ?
                <>
                    <p>Connect your wallet to track your referral comissions</p><br/>
                   
                </> :
                <>  
                    <p>Connected with: {shorten_crypto_string(address)}</p><br/>
                    <p>Total Matic Earned: {totals.matic}</p>
                    <p>Total ButtChain Earned: {totals.butcoin}</p><br/>
                    <p>Your referals are listed below</p><br/>
                    <div className="live_sales_container live_sales_container_stretch">
                        { transactions.map((item, index) => (
                            <div className="live_sales_item">
                                <img className="live_sales_icon" src={item.type == "Presale Mint" ? currencies[0].icon : butcoin_icon}/>
                                <div className="live_sales_title notranslate"><b>{short_format_number(item.referral_amount)} {item.type == "Presale Mint" ? "Matic" : "ButtChain"} Earned</b></div>
                                <div className="live_sales_desc notranslate">{short_format_number(item.amount)} ButtChain {item.type}</div>
                                <div className="live_sales_timestamp">{format_timestamp(item.timestamp)}</div>
                                <div className="live_sales_date">{format_dmy(item.timestamp)}</div>
                            </div>
                        )) }
                    </div>
                </>
            }
        </>
    )

}


export default Referrals;