import background_5 from "../images/backgrounds/background_5.png";
import liquidity_pool_icon from "../images/illustrations/liquidity_pool.png";
import butcoin_icon from "../images/icons/butcoin.png";
import toiletpaper_matic_icon from "../images/icons/toiletpaper_matic.png";
import { scroll_to_pannel, nudge } from "../services/navigation";
import contract_addresses from "../configs/contract_addresses.json"
import Clickable_Div from "./clickable_div";
import external_links from "../configs/external_links.json";

function Smart_Contracts() {

    return (
        // <div className="full_background outer_flex">
            // <img className="full_background_image" src={background_5}/>
            // <div className="container_outer">
                <div className="central_container_box forced_center">
                    <div className="cc_top_title white_background"><h2 className="handwritten">SMART CONTRACTS</h2></div>
                    <div className="standard_box_inner">
                        <div className="standard_box_text">

                            <div className="feature_container">
                                <img className="feature_img" src={butcoin_icon}/>
                                <div className="feature_text">
                                    <h3>ButtChain</h3><br/>
                                    <p>A fun new Meme Coin parody of Blockchain and Bitcoin and the entire crypto space. For degens, by degens.</p>
                                </div>
                            </div>
                            <br/>
                            <table className="standard_table notranslate">
                                <tr>
                                    <td>Blockchain</td>
                                    <td>Polygon (MATIC)</td>
                                </tr>
                                <tr>
                                    <td>Contract Address</td>
                                    <td>{contract_addresses.Butcoin}</td>
                                </tr>
                                <tr>
                                    <td>Token Symbol</td>
                                    <td>BUTT</td>
                                </tr>
                                <tr>
                                    <td>Token Decimals</td>
                                    <td>18</td>
                                </tr>
                            </table>
                            <br/>

                            <div className="feature_container">
                                <img className="feature_img" src={toiletpaper_matic_icon}/>
                                <div className="feature_text">
                                    <h3>Toilet Paper Matic</h3><br/>
                                    <p>Toilet Paper Matic is ButtChain's own version of Wrapped Matic. It helps to collect taxes while trading the Uniswap ButtChain liquidity pair.</p>
                                </div>
                            </div>

                            <br/>
                            <table className="standard_table notranslate">
                                <tr>
                                    <td>Blockchain</td>
                                    <td>Polygon (MATIC)</td>
                                </tr>
                                <tr>
                                    <td>Contract Address</td>
                                    <td>{contract_addresses.Toiletpaper_Matic}</td>
                                </tr>
                                <tr>
                                    <td>Token Symbol</td>
                                    <td>TP_MATIC</td>
                                </tr>
                                <tr>
                                    <td>Token Decimals</td>
                                    <td>18</td>
                                </tr>
                            </table>
                            <br/>
                            <div className="feature_container">
                                <img className="feature_img" src={liquidity_pool_icon}/>
                                <div className="feature_text">
                                    <h3>Liquidity Pool</h3><br/>
                                    <p>An automatically funded pool of liquidity for trading ButtChain.</p>
                                </div>
                            </div>
                            <br/>
                            <table className="standard_table notranslate">
                                <tr>
                                    <td>Blockchain</td>
                                    <td>Polygon (MATIC)</td>
                                </tr>
                                <tr>
                                    <td>Contract Address</td>
                                    <td>{contract_addresses.Liquidity_Pool}</td>
                                </tr>
                                <tr>
                                    <td>Token Pair</td>
                                    <td>BUTT / TP_MATIC</td>
                                </tr>
                                <tr>
                                    <td>Platform</td>
                                    <td>Uniswap V3</td>
                                </tr>
                                <tr>
                                    <td>Buy / Sell Tax</td>
                                    <td>10%</td>
                                </tr>
                            </table>



                        </div>

                        <Clickable_Div className="colored_button white_background light_hover" on_click={()=>window.open(external_links.audit)}>Audit Report</Clickable_Div>
                        <Clickable_Div className="colored_button green_background green_button_hover" on_click={()=>{scroll_to_pannel(0); nudge(0);}}>Buy Now</Clickable_Div>


                        <div className="standard_box_text">
                            "If this moons, I'm getting ButtChain tatooed on my ass"<br/><br/>
                            — you (after a few drinks)
                        </div>
                    </div>
                </div>
            // </div>
        // </div>
    )

}

export default Smart_Contracts;